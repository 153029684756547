
import { AxiosService } from './AxiosService';
const baseUrl = "sphere/companies";
const axiosService = AxiosService(process.env.REACT_APP_SPHERE_API_URL)

export const SphereCompanyService = {
    async checkIfCompanyExists(domain) {
        return await axiosService.getRequest(`${baseUrl}/check/${domain}`);
    },
    async getCompaniesByEmail(email) {
        return await axiosService.getRequest(`${baseUrl}/get_by_email/${email}`)
    },
    async createCompany(company) {
        return await axiosService.postRequest(`${baseUrl}/create`, company)
    },
};