import TitleSubTitle from "../../atoms/titleSubtitle/TitleSubtitle";
import styles from "./MarketingContent.module.css"
import { Button } from '@ctouch-europe-b-v/myctouch-component-library';

const MarketingContent = ({ content }) => {
    return (
        <div className={styles.container}>
            <div className={styles.background} />
            <span className={styles.content}>
                <div className={styles.body}>
                    <div className={styles.title}>
                        {content?.title?.includes("https") ?
                            <img src={content?.title} className={styles.titleImage} alt="Title"></img>
                            :
                            <TitleSubTitle title={content?.title} />
                        }
                    </div>
                    <div className={styles.description}>
                        {content?.body}
                    </div>
                    <div className={styles.actionButtons}>
                        {content?.buttonText1 && <Button type={"Primary"} text={content.buttonText1} onClick={() => content?.buttonLink1 && window.open(content?.buttonLink1)} />}
                        {content?.buttonText2 && <Button type={"Secondary Outline"} text={content.buttonText2} onClick={() => content?.buttonLink2 && window.open(content?.buttonLink2)} />}
                    </div>
                </div>
                <div className={styles.banner}>
                    <img src={content?.banner} alt="Banner" className={styles.image} />
                </div>
            </span>
        </div>
    )
}

export default MarketingContent;