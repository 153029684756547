const Routes = Object.freeze({
  home: "/",
  echo: "echo",
  origin: "origin",
  sphere: "https://ctouchsphere.eu/displayOverview",
  sla: "sla",
  accountManagement: "accountManagement",
  support: "https://support.ctouch.eu",
  settings: "userSettings",
  uboardmix: "https://uboardmix.ctouch.eu/console",
  marketingSphere: "#",
  marketingEcho: "#",
  marketinOrigin: "#",
  marketingUboard: "#",
})

export default Routes