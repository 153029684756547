import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store/configureStore'
import { Auth0Provider } from '@auth0/auth0-react';
import { WebSocketProvider } from './hooks/UseWebSocket';
import { BrowserRouter } from 'react-router-dom';

const authURL = process.env.REACT_APP_AUTH_DOMAIN;
const authID = process.env.REACT_APP_AUTH_CLIENT_ID;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Auth0Provider
      domain={authURL}
      clientId={authID}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}>
      <Provider store={store}>
        <WebSocketProvider>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </WebSocketProvider>
      </Provider>
    </Auth0Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
