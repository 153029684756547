import { TitleBanner, MenuContainer, MenuItem, ProfilePicker, BackgroundType, Footer, SideMenuContainer } from "@ctouch-europe-b-v/myctouch-component-library"
import styles from "./Echo.module.css"
import EchoSettingGroup from "../../ui/organisms/echoSettingGroup/EchoSettingGroup";
import { useEffect, useState } from "react";
import AddProfileModal from "../../ui/organisms/addProfileModal/AddProfileModal";
import { ProfileService } from "../../../services/ProfileService";
import { ProfileSettingService } from "../../../services/ProfileSettingService";
import ProfileMenuItem from "../../ui/organisms/profileMenuItem/ProfileMenuItem";
import { useSelector } from "react-redux";
import { useWebSocket } from './../../../hooks/UseWebSocket';
import MainMenu from "../../ui/molecules/mainMenu/MainMenu";
import SettingsSubMenu from "../../ui/organisms/settingsSubMenu/SettingsSubMenu";

const Echo = () => {
    const [profiles, setProfiles] = useState([{ "id": 2, "name": "Profile 1" }])
    const [selectedProfile, setSelectedProfile] = useState()
    const [settings, setSettings] = useState([])
    const [platform, setPlatform] = useState("Echo")
    const [addProfileOpen, setAddProfileOpen] = useState(false)
    const { joinRoom, send } = useWebSocket()
    const company = useSelector((state) => state.companyWithLocationsAndDisplays.value)
    const [displays, setDisplays] = useState([])
    const [isCompanySidebarOpen, setIsCompanySidebarOpen] = useState(false)
    const selectedCompany = useSelector((state) => state.selectedCompany.value);
    const user = useSelector((state) => state.user.value);

    useEffect(() => {
        if (selectedProfile) {
            ProfileService.getProfile(selectedProfile.Id).then((result) => {
                setSettings(result)
            })
        }
    }, [selectedProfile])

    useEffect(() => {
        const companyId = selectedCompany ? selectedCompany.Id : company.Id
        ProfileService.getAllProfiles(companyId).then((result) => {
            setProfiles(result);
            setSelectedProfile(result[0]);
        })
    }, [selectedCompany])

    useEffect(() => {
        let items = [];
        company?.Locations.forEach(location => {
            location.Displays.forEach(display => {
                items.push(display);
            });
        });
        setDisplays(items);
    }, [company])

    const sendSetting = (setting) => {
        let updatedSetting = {
            id: setting.id,
            currentValue: setting.value,
            launcherSettingId: setting.settingId,
            profileId: selectedProfile.Id
        }
        displays.filter((display) => {
            return display.ProfileId === selectedProfile.Id
        }).forEach(display => {
            joinRoom(display.Serial)
            send(display.Serial, setting.property, setting.value)
            if (setting.property === "logoSource" && setting.value === "CTOUCH") {
                send("logoUrl", "https://s3-alpha-sig.figma.com/img/a714/7dd8/b75a4b22d9e993739c0f6ae4856eb88e?Expires=1706486400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=PUdjCvsnn-bGPoSHvHAWefPsQs0GgB~uEgGQSQ2bnzoX0M6tEA3u4wX9LItbsdCgRJ3UB49m0aV0m3rlAKZ0T9IqvA7ndWEB-xwqsC~SYghAO8UG2lV9w~GOC6gYhEXdVp1YLVoPNx~8rtxBwRuY0MD-HaoQ2-4vsqDAlWXNJAONRyP9GJ6-7-7qopBUGJdydEhD5p2u-Q3rtDpSnKsRqouMpP1ahwj6HsqI-0-Qyc2MGK7778f1XzpHCa~MrGnQlhHUEB9cgt9wlsTALxknCeZtuzXu~j1XCCtX5WOxsW3SAnqSiFr0C2ZtKSx4GKLqs9W-S0VDWnsUg~7I-6LA-Q__")
            }
        });
        ProfileSettingService.updateProfileSetting(updatedSetting)
    }

    const refreshProfiles = () => {
        ProfileService.getAllProfiles(selectedCompany.Id).then((result) => {
            setProfiles(result);
        })
    }

    const sendAllSettings = (profileId) => {
        displays.filter((display) => {
            return display.ProfileId === profileId
        }).forEach(display => {
            joinRoom(display.Serial)
            settings.forEach((setting) => {
                send(display.Serial, setting.Setting.LauncherSettingName, setting.CurrentValue)
                if (setting.Setting.LauncherSettingName === "logoSource" && setting.CurrentValue === "CTOUCH") {
                    send("logoUrl", "https://s3-alpha-sig.figma.com/img/a714/7dd8/b75a4b22d9e993739c0f6ae4856eb88e?Expires=1706486400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=PUdjCvsnn-bGPoSHvHAWefPsQs0GgB~uEgGQSQ2bnzoX0M6tEA3u4wX9LItbsdCgRJ3UB49m0aV0m3rlAKZ0T9IqvA7ndWEB-xwqsC~SYghAO8UG2lV9w~GOC6gYhEXdVp1YLVoPNx~8rtxBwRuY0MD-HaoQ2-4vsqDAlWXNJAONRyP9GJ6-7-7qopBUGJdydEhD5p2u-Q3rtDpSnKsRqouMpP1ahwj6HsqI-0-Qyc2MGK7778f1XzpHCa~MrGnQlhHUEB9cgt9wlsTALxknCeZtuzXu~j1XCCtX5WOxsW3SAnqSiFr0C2ZtKSx4GKLqs9W-S0VDWnsUg~7I-6LA-Q__")
                }
            })
        });
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.mainContainer}>
                <MainMenu />
                <MenuContainer title="Settings" className={styles.submenu}>
                    <MenuItem type="Title" title="Version" />
                    <MenuItem type="Item" text="Echo" isSelected={platform === "Echo"} onClick={() => { setPlatform("Echo") }} />
                    <MenuItem type="Item" text="Echo Hello" isDisabled isSelected={platform === "Echo Hello"} onClick={() => { setPlatform("Echo Hello") }} />
                    <MenuItem type="Divider" />
                    <MenuItem type="Title" title="Profiles" />
                    {profiles.map((profile) => {
                        return <ProfileMenuItem onProfilePasted={(e) => { sendAllSettings(e) }} isIconDisabled={profile.ProfileName === "Default"} onProfilesAffected={() => refreshProfiles()} isSelected={selectedProfile === profile} profile={profile} type="Item" onClick={() => setSelectedProfile(profile)} />
                    })}
                    <MenuItem className={styles.spacer} type="Add" iconName="plus" text="Add Profile" onClick={() => setAddProfileOpen(true)} />
                </MenuContainer>
                <TitleBanner title="CTOUCH Echo" subtitle="The same, user-friendly interface on any CTOUCH display." color="White" className={styles.banner}></TitleBanner>
                <div className={styles.content}>
                    <ProfilePicker onClick={() => setIsCompanySidebarOpen(true)} backgroundType={BackgroundType.grey} buttonText="Change" className={styles.profilePicker} settingType={"organization"} settingTarget={selectedCompany?.Name ? selectedCompany?.Name : "yourself"} />
                    <EchoSettingGroup profile={selectedProfile} onSettingChanged={(e) => sendSetting(e)} settings={settings.filter((setting) => setting.Setting.SettingCategory.CategoryName === "apps")} areTitlesLeft title={"Icons"} subtitle={"Setup what you can access from the home screen."} />
                    <EchoSettingGroup profile={selectedProfile} onSettingChanged={(e) => sendSetting(e)} settings={settings.filter((setting) => setting.Setting.SettingCategory.CategoryName === "components").slice(0, 3)} title={"Time and date"} />
                    <EchoSettingGroup profile={selectedProfile} onSettingChanged={(e) => sendSetting(e)} settings={settings.filter((setting) => setting.Setting.SettingCategory.CategoryName === "components").slice(3, 6)} title={"Logo"} />
                    <EchoSettingGroup profile={selectedProfile} onSettingChanged={(e) => sendSetting(e)} settings={settings.filter((setting) => setting.Setting.SettingCategory.CategoryName === "background")} title={"Background"} />
                </div>
                {addProfileOpen && <AddProfileModal onSave={(e) => {
                    const profile = { profileName: e.name, companyId: selectedCompany.Id }
                    ProfileService.createProfile(profile).then((result) => {
                        ProfileSettingService.inheritProfileSettings(e.inherited, result.Id).then(() => {
                            setAddProfileOpen(false);
                            refreshProfiles()
                        });
                    })
                }} profiles={profiles} onCancel={() => setAddProfileOpen(false)} />}
            </div>
            {isCompanySidebarOpen &&
                <div>
                    <SettingsSubMenu canAccessUsers={false} className={styles.settingSubMenu} user={user} />
                    <div className={styles.overlay} onClick={() => setIsCompanySidebarOpen(false)} />
                </div>
            }
        </div>
    )
}

export default Echo;