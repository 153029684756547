import { AxiosService } from './AxiosService';

const baseUrl = "DealerCodeRequest";
const axiosService = AxiosService(process.env.REACT_APP_API_URL)

export const ResellerCodeRequestService = {

    async createResellerCodeRequest(request) {

        return await axiosService.postRequest(`${baseUrl}`, request);
    },
    async getResellerCodeRequest(companyId) {

        return await axiosService.getRequest(`${baseUrl}/company/${companyId}`);
    },

};