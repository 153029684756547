import { ModalContainer, ModalTitle, ModalParagraph, ModalButtons } from "@ctouch-europe-b-v/myctouch-component-library"

const FirstModal = ({ setPage }) => {
    return (
        <ModalContainer >
            <ModalTitle title={"Welcome!"} />
            <ModalParagraph version={"Secondary"} text="It looks like you are the first in your organization to make an account. Will you be the admin of your organization?" />
            <ModalButtons primaryButton cancelButton onCancelButtonClick={() => { setPage(2) }} cancelButtonText="Not Admin" primaryButtonText="Admin" onPrimaryButtonClick={() => { setPage(1) }} />
        </ModalContainer>
    )
}

export default FirstModal;