import { InputField, DropdownButtonContainer, DropdownButtonText, BackgroundType, DropdownContainer } from '@ctouch-europe-b-v/myctouch-component-library'
import styles from "./SearchBar.module.css"
import { useState, useEffect } from 'react';

const SearchBar = ({ isDropdown = true, dropdownOptions, onSelectOption }) => {
    const [value, setValue] = useState("");
    const [options, setOptions] = useState(dropdownOptions)
    const [currentHoveredItem, setCurrentHoveredItem] = useState("")
    useEffect(() => {
        setOptions(dropdownOptions)
    }, [dropdownOptions])
    return (
        <div>
            <InputField inputValue={value} onClick={() => { }} onChange={(e) => { setValue(e.target.value); }} type={"Search"} placeholder={"Search"} className={[styles.searchBar, styles.searchBarInput, value && styles.open].filter(e => !!e).join(" ")} />
            {(value && isDropdown) &&
                <DropdownContainer className={styles.list} backgroundType={BackgroundType.grey}>
                    {options.filter((option) => option.toString().toLowerCase().includes(value.toLowerCase())).sort((a, b) => {
                        if (a < b) { return - 1 }
                        else if (a > b) { return 1 }
                        else return 0
                    }).map((option, index) => {
                        return (
                            <DropdownButtonContainer
                                backgroundType={BackgroundType.grey}
                                onClick={(e) => { onSelectOption(option) }}
                                setCurrentHoveredItem={() => setCurrentHoveredItem(option)}
                                isHovered={currentHoveredItem === option}
                                key={index}
                                className={styles.dropdownButton}
                            >
                                <DropdownButtonText text={option} />
                            </DropdownButtonContainer>
                        )
                    })}
                </DropdownContainer>

            }
        </div>
    );
}

export default SearchBar;