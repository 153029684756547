import { AxiosService } from './AxiosService';

const baseUrl = "user";
const axiosService = AxiosService(process.env.REACT_APP_API_URL)

export const UserService = {
  async getAllUsersFromCompany(companyId) {

    return await axiosService.getRequest(`${baseUrl}/getUsersByCompanyId/${companyId}`);
  },
  async getUser(email) {

    return await axiosService.getRequest(`${baseUrl}/getByEmail/${email}`);
  },
  async updateUser(user) {

    return await axiosService.putRequest(`${baseUrl}/`, user);
  },
  async deleteUser(email) {

    return await axiosService.deleteRequest(`${baseUrl}/${email}`, email)
  },
};