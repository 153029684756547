import {AxiosService} from './AxiosService';

const baseUrl = "OriginSession";
const axiosService = AxiosService("https://localhost:44395/api/")

export const OriginSessionService = {

    async getOriginSession(email) {

        return await axiosService.getRequest(`${baseUrl}/LastSession/${email}`);
    },

};