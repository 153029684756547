import { createSlice } from "@reduxjs/toolkit";


export const userSlice = createSlice({
  name: 'user',
  initialState: {
    value: null
  },
  reducers: {
    setUser: (state, newUser) => {
      state.value = newUser.payload
    }
  }
})

export const userListSlice = createSlice({
  name: 'userList',
  initialState: {
    value: null
  },
  reducers: {
    setUserList: (state, newUsers) => {
      state.value = newUsers.payload
    },
    setOneUserList: (state, newUser) => {
      let newState = JSON.parse(JSON.stringify(state.value))
      newState = newState.filter(oldUsers => { return oldUsers.Email !== newUser.payload.Email });;
      newState = [...newState, newUser.payload]
      newState = newState.sort((a, b) => {
        const emailA = a.Email;
        const emailB = b.Email;
        return emailA.toLowerCase() > emailB.toLowerCase() ? 1 : emailA.toLowerCase() < emailB.toLowerCase() ? -1 : 0
      })
      state.value = newState;
    }
  }
})

export const selectedUserSlice = createSlice({
  name: 'selectedUser',
  initialState: {
    value: null
  },
  reducers: {
    setSelectedUser: (state, newUser) => {
      state.value = newUser.payload
    }
  }

})

export const { setUser } = userSlice.actions
export const { setUserList, setOneUserList } = userListSlice.actions

export const { setSelectedUser } = selectedUserSlice.actions

export const userReducer = userSlice.reducer
export const userListReducer = userListSlice.reducer
export const selectedUserReducer = selectedUserSlice.reducer