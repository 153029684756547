import {
    Card,
    TitleBanner,
    ItemText,
    ItemTitle,
    ProfilePicker,
    InputField,
    Switch, SideMenuContainer
} from '@ctouch-europe-b-v/myctouch-component-library'
import styles from './OriginPage.module.css';
import OriginSubMenu from "../../ui/organisms/originSubMenu/OriginSubMenu";
import { useSelector } from "react-redux";
import MainMenu from '../../ui/molecules/mainMenu/MainMenu';

const OriginPage = () => {

    const user = useSelector((state) => state.user.value);
    const selectedUser = useSelector((state) => state.selectedUser.value);
    const company = useSelector((state) => state.company.value);
    const selectedCompany = useSelector((state) => state.selectedCompany.value);


    const isAdminOrReseller = user?.role?.includes("admin") || user?.role?.includes("reseller");
    const hasNoSelection = !selectedCompany && !selectedUser;
    const hasBothSelected = selectedCompany && selectedUser;
    const hasUserSelected = !selectedCompany && selectedUser;
    const companySelected = Boolean(selectedCompany);

    const companySelectedNoUser = selectedCompany && !selectedUser;
    const showTiles = user != null && !selectedUser && !selectedCompany;
    const showCompanySettings = isAdminOrReseller && companySelectedNoUser;
    const showExternalOriginSessionPage = hasBothSelected || hasUserSelected


    const displayUser = selectedUser || user; // Prioriteit aan het geselecteerde bedrijf
    const displayCompany = selectedCompany || company; // Prioriteit aan het geselecteerde bedrijf

    let settingType;
    let settingTarget;

    if (isAdminOrReseller) {
        if (hasNoSelection) {
            settingType = "user";
            settingTarget = "yourself";
        } else if (companySelected) {
            settingType = "organization";
            settingTarget = displayCompany.Name; // Ervan uitgaande dat company een object is met een Name eigenschap
        } else if (hasBothSelected || hasUserSelected) {
            settingType = "user";
            settingTarget = selectedUser.FirstName + " " + selectedUser.LastName
        }
    }

    return (
        <div className={styles.page}>
            <MainMenu />

            <div className={styles.submenu}>
                <OriginSubMenu />
            </div>
            <div className={styles.banner}>
                <TitleBanner color="White" title="CTOUCH Origin"
                    subtitle="Easily access your personal cloud files on any CTOUCH display." imageUrl="" />
            </div>

            <div className={styles.content}>
                {isAdminOrReseller && (
                    <div className={styles.profilePicker}>
                        <ProfilePicker backgroundType={"Grey"} settingType={settingType} settingTarget={settingTarget}
                            buttonText={"Change"} />
                    </div>
                )}


                {showExternalOriginSessionPage && (
                    <div>
                        <div className={styles.settingContainer}>
                            <div className={styles.textContainer}>
                                <ItemTitle title={"Connect to display"} />
                                <br />
                                <ItemText text={"You cannot start or end sessions for another user."} />
                            </div>
                            <div className={styles.cardsContainer}>
                                <div className={styles.connectionicon}>
                                    Dit is nog geen component
                                </div>
                            </div>
                        </div>
                        <div className={styles.settingContainer}>
                            <div className={styles.textContainer}>
                                <ItemTitle title={"Connect to display"} />
                                <br />
                                <ItemText text={"Enter the display’s session ID to connect."} />
                            </div>
                            <div className={styles.cardsContainer}>
                                <Card type="Text" textColor="Grey"
                                    text="You cannot connect or disconnect accounts for another user"
                                    title="Google Drive" backgroundType="Grey"
                                    firstIcon={{ type: "fab", name: "google" }}
                                    secondIcon={{ type: "fas", name: "link-simple-slash" }} buttonText="Link" />
                                <Card type="Text"
                                    text="Connect to your Microsoft account to access your OneDrive files."
                                    title="Microsoft OneDrive" buttonColor={"White"} textColor="White"
                                    backgroundType="#00BB00"
                                    firstIcon={{ type: "fab", name: "microsoft" }}
                                    secondIcon={{ type: "fas", name: "link-simple" }}
                                    buttonText="Unlink" buttonType={"Secondary Outline Light"} />
                            </div>
                        </div>
                    </div>
                )}


                {showTiles && (
                    <div>
                        <div className={styles.settingContainer}>
                            <div className={styles.textContainer}>
                                <ItemTitle title={"Connect to display"} />
                                <br />
                                <ItemText text={"Enter the display’s session ID to connect."} />
                            </div>
                            <div className={styles.cardsContainer}>
                                <Card type="Input" textColor="White"
                                    text="Quickly connect to a display to acces your files on-screen"
                                    title="Origin"
                                    backgroundType="Color"
                                    firstIconName="cloud"
                                    secondIconName="link-simple-slash"
                                    firstIconType="fas"
                                    secondIconType="fas"
                                    placeholder="Session ID"
                                    buttonText="Connect"
                                    buttonType={"Secondary Outline Light"} />
                                <div className={styles.connectionicon}>
                                    Dit is nog geen component
                                </div>
                            </div>
                        </div>
                        <div className={styles.settingContainer}>
                            <div className={styles.textContainer}>
                                <ItemTitle title={"Connect to display"} />
                                <br />
                                <ItemText text={"Enter the display’s session ID to connect."} />
                            </div>
                            <div className={styles.cardsContainer}>
                                <Card type="Button" textColor="Grey"
                                    text="Connect to your Google account to access your Drive files."
                                    title="Google Drive" backgroundType="Grey"
                                    firstIconName="google"
                                    secondIconName="link-simple-slash"
                                    firstIconType="fab"
                                    secondIconType="fas"
                                    buttonText="Link"
                                    buttonType={"Secondary Outline"} />
                                <Card type="Button"
                                    text="Connect to your Microsoft account to access your OneDrive files."
                                    title="Microsoft OneDrive" buttonColor={"White"} textColor="White"
                                    backgroundType="#00BB00"
                                    firstIconName="microsoft"
                                    secondIconName="link-simple"
                                    firstIconType="fab"
                                    secondIconType="fas"
                                    buttonText="Unlink"
                                    buttonType={"Secondary Outline Light"} />
                            </div>
                        </div>
                    </div>
                )}
                {showCompanySettings && (<div>
                    <div className={styles.settingContainer}>
                        <div className={styles.textContainer}>
                            <ItemTitle title={"auto logout"} />
                            <br />
                            <ItemText text={"Customize your security settings."} />
                        </div>
                        <div className={styles.settingBlock}>
                            <Switch withTitle={true} title={"Auto Logout After Inactivity"} />
                            <InputField value={"10 Minutes"} state={"Active"} type={"Dropdown"} title={"Inactivity Interval"}
                                withTitle={true} options={[{ name: "5 minutes" }, { name: "10 minutes" }, { name: "Unlimited" }]} dropdownProp={["name"]}
                            />
                            <InputField value={"Unlimited"} type={"Dropdown"} state={"Active"} title={"Max Session Length"}
                                withTitle={true} options={[{ name: "5 minutes" }, { name: "10 minutes" }, { name: "Unlimited" }]} dropdownProp={["name"]} />
                        </div>
                    </div>
                    <div className={styles.textContainer}>
                        <ItemTitle title={"services"} />
                        <br />
                        <ItemText text={"Determine which cloud services can be linked by your users."} />
                    </div>
                    <div className={styles.settingBlock}>
                        <Switch withTitle={true} titleLeft={true} title={"Microsoft OneDrive"} />
                        <Switch withTitle={true} titleLeft={true} title={"Google Drive"} />
                    </div>
                </div>
                )}
            </div>
        </div>
    )
}

export default OriginPage