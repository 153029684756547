import { createSlice } from "@reduxjs/toolkit";

export const profileSlice = createSlice({
    name: "profile",
    initialState: {
        profileId: null
    },
    reducers: {
        copy: (state, action) => {
            console.log(action.payload);
            state.profileId = action.payload
        }
    }
})

export const { copy } = profileSlice.actions

export default profileSlice.reducer