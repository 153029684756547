const Permissions = Object.freeze({
  read: {
    settings: "Settings.Access",
    accountManagement: "AccountManagement.Access",
    origin: "Origin.Access",
    echo: "Echo.Access",
    SLA: "SLA.Access",
    sphere: "Sphere.Access",
    uboard: "Uboard.Access",
    echoView: "Echo.View",
    originView: "Origin.View"
  },
  update: {
    editUserStatus: "AccountManagement.Edit.UserStatus",
    ownCompany: "Settings.Edit.OwnCompany",
    internalUsers: "Settings.Edit.InternalUsers",
    externalCompany: "Settings.Edit.ExternalCompany",
    externalUsers: "Settings.Edit.ExternalUsers",
    renameProfile: "Echo.Profile.Rename",
    editProfile: "Echo.Profile.Settings.Edit",
    copyPasteProfile: "Echo.Profile.Settings.CopyPaste"
  },
  create: {
    addProfile: "Echo.Profile.Add"
  },
  delete: {
    removeProfile: "Echo.Profile.Remove"
  }
});

export default Permissions