import { ModalContainer, ModalTitle, ModalInputField, ModalButtons } from "@ctouch-europe-b-v/myctouch-component-library"
import { useState } from "react";

const RenameProfileModal = ({ profile, onCancel, onSave }) => {
    const [name, setName] = useState(profile.ProfileName)
    console.log(profile);
    return (
        <ModalContainer hasOverlay>
            <ModalTitle title={"Rename Profile"} />
            <ModalInputField type="Text" placeholder="Name" description="Name" value={name} onChange={(e) => { setName(e.target.value) }} />
            <ModalButtons primaryButton cancelButton onCancelButtonClick={onCancel} primaryButtonText="Save" onPrimaryButtonClick={() => { profile.ProfileName = name; onSave(profile) }} />
        </ModalContainer>
    )
}

export default RenameProfileModal;