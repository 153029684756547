import { useAuth0 } from "@auth0/auth0-react";
import { ModalContainer, ModalTitle, ModalParagraph, ModalButtons } from "@ctouch-europe-b-v/myctouch-component-library"
import { MailService } from "../../../../services/MailService";
import { useEffect, useState } from "react";
import { SphereUserService } from "../../../../services/SphereUserService";

const WaitingForAdminModal = ({ name, email, mailAlreadySent }) => {
    const { logout, user } = useAuth0()
    const [mailSent, setMailSent] = useState(mailAlreadySent)

    const formatText = () => {
        return <>
            You have requested to join <strong>{name}</strong>. Your admin <strong>{email}</strong> has been notified to grant you access.
            <br /><br />
            If this is incorrect, please contact support.
        </>
    }

    useEffect(() => {
        if (!mailSent) {
            MailService.sentNewUserRequestToAdmin(email, user.email).then(response => {
                if (response.message === "Email Sent") {
                    setMailSent(true)
                }
            })
        }
    }, [mailSent, mailAlreadySent])

    return (
        <ModalContainer >
            <ModalTitle title={"Waiting for your admin..."} />
            <ModalParagraph version={"Secondary"} text={formatText()} />
            <ModalButtons primaryButton cancelButton cancelButtonText="Contact Support" onCancelButtonClick={() => window.open("https://support.ctouch.eu", "_blank", "noreferrer")} primaryButtonText="Close" onPrimaryButtonClick={() => { logout({ logoutParams: { returnTo: window.location.origin } }) }} />
        </ModalContainer>
    );
}

export default WaitingForAdminModal;