import styles from "./TitleSubTitle.module.css"

const TitleSubTitle = ({ title, subtitle, className }) => {
    return (
        <span className={[styles.container, className && className].filter(e => !!e).join(" ")}>
            {title && <span className={styles.title}>{title}</span>}
            {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
        </span>
    )
}

export default TitleSubTitle;