import { SideMenuContainer, MenuButton, Logo, MenuButtonType, Button, TitleBannerTitle } from '@ctouch-europe-b-v/myctouch-component-library';
import styles from './NotFoundPage.module.css';
import MainMenu from "../../ui/molecules/mainMenu/MainMenu";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TitleSubTitle from '../../ui/atoms/titleSubtitle/TitleSubtitle';
import { useLocation, useNavigate } from 'react-router-dom';
import Routes from '../../../enums/Routes';

const NotFoundPage = ({ }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className={styles.container}>
      <MainMenu className={styles.mainMenu} />
      <div className={styles.page}>
        <div className={styles.titleContainer}>
          <FontAwesomeIcon className={styles.ghost} icon={["fas", "ghost"]} />
          <span className={styles.title} >OOOOOps... nothing here</span>
        </div>
        <TitleSubTitle className={styles.text} title={"Sorry, this page can't be found"} subtitle={"Error code: 404"} />
        <div className={styles.buttons}>
          <Button type={"Primary"} text={"Home"} onClick={() => navigate(Routes.home)} />
          <Button type={"Secondary"} text={"Back"} onClick={() => navigate(-1)} />
        </div>
      </div>
    </div>)
}

export default NotFoundPage