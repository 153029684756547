import { AxiosService } from './AxiosService';

const baseUrlV2 = "/registration";
const axiosService = AxiosService(process.env.REACT_APP_SPHERE_API_URL)
const axiosServiceV2 = AxiosService(process.env.REACT_APP_SPHERE_API_V2_URL)

export const SlaService = {
    async getSlaData(serial) {
        let body = {
            serial: serial
        };

        return await Promise.all([
            axiosServiceV2.postRequest(`${baseUrlV2}/getDisplayDetails`, body),
            axiosServiceV2.postRequest(`${baseUrlV2}/getRegistrations`, body),
            axiosService.postRequest(`/myCTOUCH/getSubscriptionType`, body),
            axiosService.getRequest(`/sphere/displays/${serial}/check_display_exists`),
        ]).then((results) => {
            let subscriptionType = { heartbeat: "" };
            let registered = { registered: getRegistered(results[3].results?.length > 0, results[1].results?.length > 0) };
            // console.log(results[0].results.length > 0 || results[1].results.length > 0)
            // if (results[0].results.length > 0 || results[1].results.length > 0) {

            // }
            if (typeof results[2].results === 'object' && results[2].results !== null) {
                subscriptionType.heartbeat = results[2].results.name;
            } else {
                subscriptionType.heartbeat = results[2].results;
            }

            if (results[0].results.length === 0) {
                results[0].results.push({});
            } else {
                results[0].results[0].type = results[0].results[0].model;
            }

            if (results[1].results.length === 0) {
                results[1].results.push({});
            }

            return Object.assign(results[0].results[0], results[1].results[0], subscriptionType, registered);
        });
    },
}

function getRegistered(isRegisteredInSphere, isRegisteredInWarrantyTool) {
    if (isRegisteredInSphere || isRegisteredInWarrantyTool) {
        return isRegisteredInSphere ? "Yes (Sphere)" : "Yes (Warranty)";
    } else {
        return "No";
    }
}
