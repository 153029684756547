import { ModalContainer, ModalTitle, ModalParagraph, ModalButtons } from "@ctouch-europe-b-v/myctouch-component-library"

const PasteSettingsModal = ({ onCancel, onPaste }) => {
    return (
        <ModalContainer hasOverlay>
            <ModalTitle title={"Paste Settings"} />
            <ModalParagraph type="Info" text={"Are you sure you want to paste these settings? The current settings will be overwritten."} />
            <ModalButtons primaryButton cancelButton onCancelButtonClick={onCancel} primaryButtonText="Paste" onPrimaryButtonClick={onPaste} />
        </ModalContainer>
    )
}

export default PasteSettingsModal;