import styles from './ContextMenuVerifyDenyUser.module.css';
import { ContextMenuContainer, ContextMenuItem } from '@ctouch-europe-b-v/myctouch-component-library';
import { forwardRef, useContext } from 'react';
import useUpdateUser from '../../../../hooks/useUpdateUser';
import { MultiSelectDispatchContext } from '../../../../contexts/MultiSelectContext';

const ContextMenuVerifyDenyUser = forwardRef(function ContextMenuVerifyDenyUser({ popupDirection, userId, isVerified, isDenied, setActiveModal, className, onClose }, ref) {
  const updateUserHandler = useUpdateUser();
  const multiUserDispatch = useContext(MultiSelectDispatchContext)
  const userHandler = (action) => {
    switch (action) {
      case "verify":
        updateUserHandler("UserStatus", "Verified", userId)
        multiUserDispatch({ type: "Remove One", id: userId })
        break;
      case "deny":
        updateUserHandler("UserStatus", "Denied", userId)
        multiUserDispatch({ type: "Remove One", id: userId })
        break;
      case "remove":
        setActiveModal({ name: "RemoveUser", info: { id: userId } })
        console.log("Remove User")
        break;
      default:
        break;
    }
    onClose()
  }
  return (
    <ContextMenuContainer ref={ref} className={[styles.customWidth, className, styles.tempContext, styles[popupDirection]].filter(e => !!e).join(" ")} backgroundType={"White"} popupDirection={popupDirection}>
      <ContextMenuItem isDisabled={isVerified} backgroundType={"White"} type={"Item"} iconName="check" onClick={() => userHandler("verify")} text={"Verify"} />
      <ContextMenuItem isDisabled={isDenied} backgroundType={"White"} type={"Item"} iconName="xmark" onClick={() => userHandler("deny")} text={"Deny"} />
      <ContextMenuItem backgroundType={"White"} type={"Divider"} />
      <ContextMenuItem backgroundType={"White"} type={"Item"} version={"Warning"} iconName="trash" onClick={() => userHandler("remove")} text={"Remove"} />
    </ContextMenuContainer>
  )
})

export default ContextMenuVerifyDenyUser