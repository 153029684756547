import { useState } from "react";
import styles from "./ConfirmableTextInput.module.css"
import { InputField, Button } from '@ctouch-europe-b-v/myctouch-component-library';

const ConfirmableTextInput = ({ title, placeholder, isDisabled, buttonText, onConfirm, value }) => {
    const [inputValue, setInputValue] = useState(value)
    return (
        <div className={styles.container}>
            <InputField backgroundType="Grey" withTitle type="Text" title={title} state={isDisabled ? "Disabled" : "Active"} placeholder={placeholder} onChange={(e) => setInputValue(e.target.value)} inputValue={inputValue} />
            <Button type={"Primary"} text={buttonText} isDisabled={isDisabled} onClick={() => onConfirm(inputValue)} />
        </div>
    )
}

export default ConfirmableTextInput;