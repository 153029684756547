import {
    MenuContainer,
    MenuItem
} from '@ctouch-europe-b-v/myctouch-component-library';
import styles from "./SlaSubMenu.module.css";
import { useState } from 'react';

const SlaSubMenu = ({ firmwareLink, searchOnClick }) => {
    const [isSearchSelected, setIsSearchSelected] = useState(false);
    const [isSearchActive, setisSearchActive] = useState("")

    return (
        <div className={styles.container}>
            <MenuContainer className={styles.menuContainer} title={"Search"}>
                <MenuItem type={"Title"} title={"SLA Data"} />
                <MenuItem type={"Paragraph"} text={"Enter the display’s serial number to find the corresponding data."} />
                <MenuItem type={"Divider"} />
                <MenuItem type={"Title"} title={"Search"} />
                <MenuItem type={"Search"} placeholder={"Serial Number"} onClick={() => setIsSearchSelected(true)} onChange={(e) => { e !== undefined && e !== "" && searchOnClick(e); setisSearchActive(e) }} isSelected={isSearchSelected || isSearchActive !== ""} onBlur={() => setIsSearchSelected(false)} />
                <MenuItem type={"Divider"} />
                <MenuItem type={"Title"} title={"Actions"} />
                <MenuItem type={"Action"} text={"Firmware Link"} isDisabled={firmwareLink == null} onClick={() => window.open(firmwareLink, "_blank")} />
            </MenuContainer>
        </div>
    );
};

export default SlaSubMenu;