import { ModalContainer, ModalTitle, ModalButtons, ModalParagraph } from '@ctouch-europe-b-v/myctouch-component-library'
import { useAuth0 } from "@auth0/auth0-react";

const VerifyMailModal = () => {
    const { logout } = useAuth0();

    return (
        <ModalContainer >
            <ModalTitle title={"Verify Account"} />
            <ModalParagraph version={"Secondary"} text="Your account has been created successfully. Please check your email to verify your account. Don’t forget to check your spam folder if you don’t see it." />
            <ModalButtons primaryButton primaryButtonText="Close" onPrimaryButtonClick={() => { logout({ logoutParams: { returnTo: window.location.origin } }) }} />
        </ModalContainer>
    )
}

export default VerifyMailModal;