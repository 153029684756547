import { ModalContainer, ModalTitle, ModalParagraph, ModalButtons } from "@ctouch-europe-b-v/myctouch-component-library"

const RemoveProfileModal = ({ profileId, onCancel, onRemove }) => {
    return (
        <ModalContainer hasOverlay>
            <ModalTitle title={"Remove Profile"} />
            <ModalParagraph type="Info" text={"Are you sure you want to remove this profile? All displays using this profile will revert to default settings."} />
            <ModalButtons primaryButton cancelButton onCancelButtonClick={onCancel} primaryButtonText="Remove" primaryButtonType="Danger" onPrimaryButtonClick={() => onRemove(profileId)} />
        </ModalContainer>
    )
}

export default RemoveProfileModal;