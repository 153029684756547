import { useAuth0 } from "@auth0/auth0-react";
import { ModalContainer, ModalTitle, ModalParagraph, ModalButtons } from "@ctouch-europe-b-v/myctouch-component-library";

const ThanksModal = ({ name }) => {
    const { logout } = useAuth0();

    const formatText = () => {
        return <>Your organization  <strong>{name}</strong> has been created and your account is ready to use.</>
    }

    return (
        <ModalContainer >
            <ModalTitle title={"All done!"} />
            <ModalParagraph version={"Secondary"} text={formatText()} />
            <ModalButtons primaryButton primaryButtonText="Get Started" onPrimaryButtonClick={() => { logout({ logoutParams: { returnTo: window.location.origin } }) }} />
        </ModalContainer>
    )
}

export default ThanksModal;