import {
    MenuContainer,
    MenuItem,
} from '@ctouch-europe-b-v/myctouch-component-library'
import styles from "./SettingsSubMenu.module.css"
import { useDispatch, useSelector } from "react-redux";
import { setSelectedUser, setUserList } from "../../../../redux/user/userSlice";
import { setSelectedCompany } from "../../../../redux/company/companySlice";
import { UserService } from "../../../../services/UserService";
import { useEffect, useState } from 'react';

const SettingsSubMenu = ({ user, className, canAccessUsers = true }) => {
    //Dispatch for redux
    const dispatch = useDispatch();

    //This is an array of users based on company ID
    const userList = useSelector((state) => state.userList.value)

    //This object contains the user info of the currently selected user
    const selectedUser = useSelector((state) => state.selectedUser.value)

    //This object contains the company info of the currently selected company
    const selectedCompany = useSelector((state) => state.selectedCompany.value)

    //This is an array of users based on reseller code
    const companyList = useSelector((state) => state.companyList.value)

    //This object contains the company info of the logged in user
    const company = useSelector((state) => state.company.value)

    const isUserAdmin = user?.Roles?.some(item => item?.Name === "Administrator");

    const isUserReseller = user?.Roles?.some(item => item?.Name === "Reseller");

    const isUserCTOUCH = user?.Roles?.some(item => item?.Name === "CTOUCH");

    const isUserUser = user?.Roles?.some(item => item?.Name === "User");

    const [accessUsers, setAccessUsers] = useState(canAccessUsers)

    useEffect(() => {
        if ((isUserReseller && !isUserAdmin) || (isUserCTOUCH && !isUserAdmin) && selectedCompany === null) {
            setAccessUsers(false)
        } else if ((isUserReseller || isUserCTOUCH) && !isUserAdmin && selectedCompany !== null) {
            setAccessUsers(true)
        }
    }, [selectedCompany])

    //Function to set the new selected user in redux state
    const handleNewSelectedUser = (newUser) => {
        dispatch(setSelectedUser(newUser));
    }

    //Function to set the new selected company in redux state
    const handleNewSelectedCompany = (newCompany) => {
        dispatch(setSelectedCompany(newCompany));
        console.log(newCompany);
        fetchUsers(newCompany.Id)
        dispatch(setSelectedUser(null))
    }

    //Function to reset the selected company redux state
    const handleCompanyReset = () => {
        dispatch(setSelectedCompany(null))
        handleUserReset()
        fetchUsers(company.Id)
    }

    //Function to reset the selected company redux state
    const handleUserReset = () => {
        dispatch(setSelectedUser(null))
    }

    //Function to fetch all the users within a company
    const fetchUsers = (id) => {
        UserService.getAllUsersFromCompany(id).then((userList) => {
            dispatch(setUserList(userList))
        })
    }
    console.log(companyList)

    const sortCollectionList = (list, prop) => {
        const newList = list.sort((a, b) => {
            const aProp = a[prop];
            const bProp = b[prop];
            if (aProp < bProp) return - 1
            else if (aProp > bProp) return 1;
            else return 0;
        })
        console.log(newList)
        return newList
    }
    // console.log(sortCollectionList(companyList, "Name"))
    return (
        <div className={[styles.container, className && className].filter(e => !!e).join(" ")}>
            <MenuContainer className={styles.menuContainer} title={"Account"}>
                {isUserAdmin && !isUserReseller
                    ? <MenuItem type={"Title"} title={"Admin Access"} />
                    : (isUserReseller || isUserCTOUCH)
                        ? <MenuItem type={"Title"} title={"Reseller Access"} />
                        : <MenuItem type={"Title"} title={"User Access"} />
                }
                {isUserUser && (!isUserCTOUCH && !isUserReseller)
                    ? <MenuItem type={"Paragraph"} text={formatUserText(user.FirstName, user.LastName)} />
                    : <MenuItem type={"Paragraph"} text={formatSettingParagraph(selectedUser, selectedCompany)} />}
                {isUserAdmin && !isUserReseller && !isUserCTOUCH
                    ? <>
                        <MenuItem type={"Divider"} />
                        <MenuItem type={"Title"} title={"Organization"} />
                        <MenuItem isSelected={selectedUser === null} type={"Action"} text={company.Name} iconName="building" />
                        <MenuItem type={"Divider"} />
                        <MenuItem type={"Title"} title={"User"} />
                        <MenuItem type={"Collection"}
                            selected={selectedUser !== null ? selectedUser : undefined}
                            visualDropdownProp={["FirstName", "LastName"]}
                            lookUpDropdownProp={["Email"]}
                            text={selectedUser ? selectedUser.LastName : "Select"}
                            list={userList}
                            setSelected={handleNewSelectedUser} />

                        {selectedUser ? <MenuItem type={"Action"} text={"Reset"} iconName="arrow-rotate-left" onClick={handleUserReset} /> : <></>}
                    </>
                    : isUserReseller || isUserCTOUCH
                        ? <>
                            <MenuItem type={"Divider"} />
                            <MenuItem type={"Title"} title={"Organization"} />
                            <MenuItem type={"Collection"}
                                text={selectedCompany ? selectedCompany : "Select"}
                                selected={selectedCompany ? selectedCompany : undefined}
                                list={!isUserAdmin ? sortCollectionList(companyList.filter(companyItem => companyItem.Name !== company.Name), "Name") : sortCollectionList(companyList.filter(companyItem => { console.log(companyItem); return typeof companyItem.Name === "string" }), "Name")}
                                visualDropdownProp={["Name"]}
                                lookUpDropdownProp={["Name"]}
                                setSelected={(e) => handleNewSelectedCompany(e)}
                            />

                            {selectedCompany ? <MenuItem type={"Action"} text={"Reset"} iconName="arrow-rotate-left" onClick={handleCompanyReset} /> : <></>}

                            <MenuItem type={"Divider"} />
                            <MenuItem type={"Title"} title={"User"} />
                            <MenuItem type={"Collection"}

                                isSelected={selectedUser !== null}

                                text={selectedUser ? selectedUser : "Select"}
                                selected={selectedUser !== null ? selectedUser : undefined}

                                iconName="display"
                                visualDropdownProp={["FirstName", "LastName"]}
                                lookUpDropdownProp={["Email"]}
                                list={userList}
                                isDisabled={!accessUsers}
                                setSelected={handleNewSelectedUser} />

                            {selectedUser ? <MenuItem type={"Action"} text={"Reset"} iconName="arrow-rotate-left" onClick={handleUserReset} /> : <></>}
                        </>
                        : null}
            </MenuContainer>
        </div>)
}

//Formatting the text of the paragraph in the submenu for a user
const formatUserText = (firstName, lastName) => {
    return (
        <>
            {"Hi "}
            <strong>{firstName + " " + lastName}</strong>
            {", anything you would like to change here?"}
        </>
    );
};

//Formatting the text of the paragraph in the submenu for a reseller and admin
const formatSettingParagraph = (selectedUser, selectedCompany) => {
    const placeholder = () => {
        return selectedUser?.FirstName === null && selectedUser?.LastName === null ? selectedUser.Email
            : selectedUser?.FirstName !== null && selectedUser?.LastName === null ? selectedUser?.FirstName
                : selectedUser?.FirstName === null && selectedUser?.LastName !== null ? selectedUser?.LastName
                    : `${selectedUser?.FirstName} ${selectedUser?.LastName}`
    }

    return (
        <>
            {"Use the settings below to easily switch between personal, organization, or other user’s settings."}
            <br /><br />
            {"You are currently editing the settings of "}
            {selectedUser ?
                <strong>{placeholder()}</strong> :
                selectedCompany ?
                    <strong>{selectedCompany?.Name}</strong> :
                    <strong>yourself</strong>
            }
            {"."}
        </>
    );
};

export default SettingsSubMenu;
