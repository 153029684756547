import { Card } from "@ctouch-europe-b-v/myctouch-component-library"
import styles from "./CardGrid.module.css"
import { useState } from "react"
const CardGrid = ({ cards, user }) => {
    const [userPermissions, setUserPermissions] = useState(user.Permissions[0].User)
    const [companyPermissions, setCompanyPermissions] = useState(user.Permissions[0].Company)

    const buttonPermissionCheckHandler = (card, type) => {
        const marketingVersion = `marketing${type.replace(/^[a-z]{1}/y, type.substring(0, 1).toUpperCase())}`
        return (card?.accessPermission == null
            || ((
                (!card.title.includes("SLA") && companyPermissions.includes(card.accessPermission))
                || ((card.title.includes("SLA") || card.title.includes("Account")) && userPermissions.includes(card.accessPermission))
            )
                && (card.viewPermission === undefined
                    || (card.viewPermission !== undefined && userPermissions.includes(card.viewPermission))
                )
            )
        ) ? card[type] : card[marketingVersion]
    }

    const invisibleCardChecker = (card) => {
        if (card.title.includes("SLA")) {
            return !userPermissions.includes(card.accessPermission);
        }
        if (card.title.includes("Sphere")) {
            return companyPermissions.includes(card.accessPermission) && user.SphereAccess === 0
        }
        if (!userPermissions.includes(card.accessPermission)) {
            return !companyPermissions.includes(card.accessPermission) || companyPermissions.includes(card.accessPermission) && (card.viewPermission !== undefined && !userPermissions.includes(card.viewPermission))
        }
    }

    return (
        <div className={styles.grid}>
            {
                cards.map((card) => {
                    if (card.isInvisibleWhenNoPermission
                        &&
                        invisibleCardChecker(card)
                    ) {
                        return null
                    } else {
                        return ((card?.accessPermission === null
                            || (card?.accessPermission !== null && (card.viewPermission !== undefined && card.viewPermission === null)))
                            || card.marketingOnClick !== null) ?
                            <Card
                                type={card.type}
                                text={card.text}
                                title={card.title}
                                firstIconType={card.firstIconType}
                                firstIconName={card.firstIconName}
                                secondIconType={card.secondIconType !== undefined ? card.secondIconType : undefined}
                                secondIconName={card.secondIconName !== undefined ? card.secondIconName : ""} //Temp solution, but gives an error log
                                onChange={card.onChange}
                                onClick={buttonPermissionCheckHandler(card, "onClick")}
                                onTextClick={card.onTextClick}
                                buttonType={card.buttonType}
                                buttonText={buttonPermissionCheckHandler(card, "buttonText")}
                                backgroundType={card.backgroundType}
                                isDisabled={card.isDisabled}
                                placeholder={card.placeholder}
                                value={card.value}
                                intervalTime={card.intervalTime}
                                state={card.state}
                                className={card.className}
                                circleColor={card.circleColor}
                                textColor={card.textColor}
                                color={card.color}
                            />
                            : null
                    }
                })
            }
        </div>
    )

}

export default CardGrid