const PageKeywords = Object.freeze({

    // Origin
    "Origin": "origin",
    "Origin - Cloud Access": "origin",
    "Origin - Personal Cloud": "origin",
    "Origin - Personal Files": "origin",
    "Origin - Google Drive": "origin",
    "Origin - Microsoft OneDrive": "origin",
    "Origin - Link Account": "origin",
    "Origin - Auto Logout": "origin",

    // Account management
    "Account management": "accountManagement",
    "Account management - Accounts": "accountManagement",
    "Account management - Users": "accountManagement",
    "Account management - Add user": "accountManagement",
    "Account management - Add account": "accountManagement",
    "Account management - Invite user": "accountManagement",
    "Account management - Verify user": "accountManagement",
    "Account management - Verify account": "accountManagement",
    "Account management - Deny user": "accountManagement",
    "Account management - Deny account": "accountManagement",
    "Account management - Sphere access": "accountManagement",
    "Account management - User status": "accountManagement",
    "Account management - Account status": "accountManagement",

    //Echo
    "Echo": "echo",
    "Echo - Echo Hello": "echo",
    "Echo - Display Interface": "echo",
    "Echo - Interface": "echo",
    "Echo - Home Screen": "echo",
    "Echo - Display Logo": "echo",
    "Echo - Display Background": "echo",

    //Settings
    "Settings": "settings",
    "Settings - Name": "settings",
    "Settings - Email": "settings",
    "Settings - External Access": "settings",
    "Settings - CTOUCH Access": "settings",
    "Settings - Reseller Access": "settings",
    "Settings - Reseller Code": "settings",
    "Settings - Reseller Account": "settings",
    "Settings - Credentials": "settings",
    "Settings - Password": "settings",
    "Settings - Select User": "settings",
    "Settings - Select Organization": "settings",
    "Settings - Select Customer": "settings",

    //Sla tool
    "SLA": "sla",
    "SLA - SLA Checker": "sla",
    "SLA - SLA Tool": "sla",
    "SLA - Display Info": "sla",
    "SLA - Firmware Link": "sla",
});

export default PageKeywords