import { useDispatch, useSelector } from 'react-redux';
import useUpdateUser from '../../../../hooks/useUpdateUser';
import styles from './ModalSphereAccess.module.css';
import { ModalContainer, ModalParagraph, ModalTitle, ModalButtons, ButtonType } from '@ctouch-europe-b-v/myctouch-component-library';
import { setUserList } from '../../../../redux/user/userSlice';

const ModalSphereAccess = ({ userId, setActiveModal, isGive }) => {
  const userList = useSelector(state => state.userList.value)
  const user = useSelector(state => state.user.value);
  const updateUserHandler = useUpdateUser();
  const dispatch = useDispatch();

  const giveAccess = () => {
    updateUserHandler("SphereAccess", 1, userId);
  }
  const revokeAccess = () => {
    updateUserHandler("SphereAccess", 0, userId);
  }
  const handleAccess = () => {
    isGive ?
      giveAccess()
      :
      revokeAccess()
    setActiveModal("none")
    console.log(isGive ? "Giving Access" : "Revoking Access")
  }
  return (
    <ModalContainer hasOverlay className={styles.height} onClick={() => setActiveModal("none")}>
      <ModalTitle title={isGive ? "Allow Sphere Access" : "Revoke Sphere Access"} />
      <ModalParagraph className={styles.text} text={isGive ? "Are you sure you want to give this user access to Sphere? This means they will be able to manage your display settings." : "Are you sure you want to revoke Sphere access for this user? This means they will no longer be able to manage your display settings."} type={"Info"} version={"Secondary"} />
      <ModalButtons cancelButton cancelButtonType={ButtonType.secondaryOutline} onCancelButtonClick={() => { setActiveModal("none"); dispatch(setUserList(userList)) }} primaryButton primaryButtonText={isGive ? "Give Access" : "Revoke Access"} primaryButtonType={isGive ? ButtonType.primary : ButtonType.danger} onPrimaryButtonClick={() => handleAccess()} />
    </ModalContainer>
  )
}

export default ModalSphereAccess;