import { createContext, useReducer } from 'react';

export const MultiSelectContext = createContext([]);
export const MultiSelectDispatchContext = createContext(null);

export function MultiSelectProvider({ children }) {
  const [multiSelected, dispatch] = useReducer(
    multiSelectReducer,
    initial
  );

  return (
    <MultiSelectContext.Provider value={multiSelected}>
      <MultiSelectDispatchContext.Provider value={dispatch}>
        {children}
      </MultiSelectDispatchContext.Provider>
    </MultiSelectContext.Provider>
  )
}

const multiSelectReducer = (multiSelected, action) => {
  switch (action.type) {
    case "Add All": {
      return action.users
    }
    case "Remove All": {
      return [];
    }
    case "Add One": {
      return [...multiSelected, {
        id: action.id,
        status: action.status,
      }]
    }
    case "Remove One": {
      return multiSelected.filter(ms => ms.id !== action.id);
    }
    default: {
      throw Error(`Unknown MultiSelect action: ${action.type}`)
    }
  }
}

const initial = [];