import { ModalContainer, ModalTitle, ModalParagraph, ModalButtons, ModalInputField } from "@ctouch-europe-b-v/myctouch-component-library";
import { useEffect, useState } from "react";
import { MailService } from "../../../../services/MailService";

const NotifyAdminModal = ({ user, setPage }) => {
    const [adminMail, setAdminMail] = useState("")
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const mailRegex = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;

    useEffect(() => {
        if (adminMail.length > 0 && adminMail.match(mailRegex)) setSubmitDisabled(false)
        else setSubmitDisabled(true)
    }, [adminMail])

    const handleSentAdminMail = () => {
        let mail = { "mail": adminMail }
        let userMail = { "mail": user.email }

        MailService.sentRequestAdmin(mail).then(() => {
            MailService.sentSentToAdminConfirmation(userMail).then(() => {
                setPage(3)
            })
        })
    }

    return (
        <ModalContainer >
            <ModalTitle title={"Notify your admin"} />
            <ModalParagraph text="An admin is required to setup an organization. Please provide their email address so we can ask them to make an account." />
            <ModalInputField description="Admin Email" placeholder="Email" value={adminMail} onChange={(e) => setAdminMail(e.target.value)} />
            <ModalButtons primaryButton primaryButtonDisabled={submitDisabled} primaryButtonText="Send" onPrimaryButtonClick={() => { handleSentAdminMail() }} />
        </ModalContainer>
    )
}

export default NotifyAdminModal;