import { useEffect, useRef, useState } from 'react';
import AccountManagementTableHeader from '../../molecules/accountManagementTableHeader/AccountManagementTableHeader';
import AccountManagementTableRow from '../../molecules/accountManagementTableRow/AccountManagementTableRow';
import styles from './AccountManagementTable.module.css';

const AccountManagementTable = ({ users, currentUser, multiUsers, setMultiUsers, activeFilters, setActiveModal, setGiveAccessUser }) => {
  const tableRef = useRef();
  const [minWidth, setMinWidth] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const rect = tableRef.current.getBoundingClientRect();
      if (rect.width < 774) {
        setMinWidth(true)
      }
    }
    window.addEventListener("load", handleResize)
    window.addEventListener("resize", handleResize);
    return () => { window.removeEventListener("resize", handleResize); window.removeEventListener("load", handleResize) };
  }, [])

  const activeUserHandler = (type, e) => {
    switch (type) {
      case "All":
        if (e.target.previousSibling !== null && e.target.previousSibling.type === "checkbox") {
          setMultiUsers(() => { users.forEach(user => multiUsers = [...multiUsers, user]); return multiUsers });
        } else {
          setMultiUsers([]);
        }
        break;
      case "User":
        if (e.target.previousSibling !== null && e.target.previousSibling.type === "checkbox") {
          setMultiUsers(() => multiUsers = !multiUsers.includes(e.target.value) && [...multiUsers, e.target.value]);
        } else {
          setMultiUsers(() => multiUsers = multiUsers.includes(e.target.value) ? multiUsers.filter(user => user !== e.target.value) : multiUsers);
        }

        break;
      default:
        return;
    }
  }


  return (
    <div ref={tableRef} className={[styles.table, minWidth && styles.minWidth].filter(e => !!e).join(" ")}>
      <AccountManagementTableHeader
        className={styles.tableHeader}
        activeUserHandler={activeUserHandler}
        users={users}
      />
      <div className={styles.tableBody}>
        {users.map((user, index) => {
          return <AccountManagementTableRow
            className={styles.tableRow}
            key={index}
            multiUsers={multiUsers}
            user={user}
            currentUser={currentUser}
            setActiveModal={setActiveModal}
            activeUserHandler={activeUserHandler}
          />
        })}
      </div>
    </div>
  )
}

export default AccountManagementTable;