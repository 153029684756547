import { AxiosService } from './AxiosService';

const baseUrl = "/mailservice";
const axiosService = AxiosService(process.env.REACT_APP_SPHERE_API_URL)

export const MailService = {
    async sentRequestAdmin(mail) {
        return await axiosService.postRequest(`${baseUrl}/adminRequested`, mail)
    },

    async sentSentToAdminConfirmation(mail) {
        return await axiosService.postRequest(`${baseUrl}/mailSentToAdmin`, mail)
    },

    async sentNewUserRequestToAdmin(mail, new_user_email) {
        return await axiosService.postRequest(`${baseUrl}/adminNewAccountRequest`, { mail, new_user_email })
    },

    async sentRequestResellerCode(mail, company_id) {
        return await axiosService.postRequest(`${baseUrl}/requestResellerCode/${company_id}`, { mail })
    },

    async sentNewAccountApproved(mail) {
        return await axiosService.postRequest(`${baseUrl}/newAccountApproved`, { mail })
    },

    async sentInviteUser(mail) {
        return await axiosService.postRequest(`${baseUrl}/inviteNewUser`, mail)
    }
}