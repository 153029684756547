import { AxiosService } from './AxiosService';
const baseUrl = "User/Roles";
const axiosService = AxiosService(process.env.REACT_APP_RPA_BASE_URL)

export const UserRoleService = {
  async create(role) {
    return await axiosService.postRequest(`${baseUrl}/Add`, role);
  },
  async update(user) {
    return await axiosService.postRequest(`${baseUrl}/update`, user);
  },
  async getUserRoles(email) {
    return await axiosService.getRequest(`${baseUrl}/${email}`, email)
  }
};