import { createContext, useContext, useEffect } from "react";
import { io } from "socket.io-client";

export const socket = io(process.env.REACT_APP_WS_URL);

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  useEffect(() => {
    socket.connect();
    function onConnect() {

    }
    socket.on("connect_error", (err) => {
    });
    socket.on("connection", onConnection);
    function onConnection() {
    }
    function onDisconnect() {
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
    };
  })

  const joinRoom = (serial) => {
    socket.emit("joinRoom", serial)
  }

  const send = (serial, property, value) => {
    switch (property) {
      case "whiteboard":
        socket.emit("setWhiteboardEnabled", serial, value);
        break;
      case "screenSharing":
        socket.emit("setScreenSharingEnabled", serial, value);
        break;
      case "webBrowser":
        socket.emit("setWebBrowserEnabled", serial, value);
        break;
      case "appLibrary":
        socket.emit("setAppEnabled", serial, value);
        break;
      case "timeAndDate":
        socket.emit("setDateTimeEnabled", serial, value);
        break;
      case "timeAndDateColor":
        socket.emit("setDateTimeColor", serial, value);
        break;
      case "timeAndDateFormat":
        socket.emit("setDateTimeFormat", serial, value);
        break;
      case "logo":
        socket.emit("setLogoEnabled", serial, value);
        break;
      case "logoSource":
        socket.emit("setLogoSource", serial, value);
        break;
      case "logoUrl":
        socket.emit("setLogo", serial, value);
        break;
      case "source":
        socket.emit("setBackgroundSource", serial, value);
        break;
      case "imageUrl":
      case "pageUrl":
      case "backgroundImageUrl":
        socket.emit("setBackground", serial, value);
        break;
      case "interaction":
        socket.emit("setBackgroundInteraction", serial, value);
        break;
      default:
        break;
    }
  }

  return (<WebSocketContext.Provider value={{ send, joinRoom }}> {children} </WebSocketContext.Provider>);

}

export const useWebSocket = () => { const context = useContext(WebSocketContext); if (!context) { throw new Error("useWebSocket must be used within a WebSocketProvider component"); } return context; };