import styles from './DeniedUserModal.module.css';
import { useAuth0 } from "@auth0/auth0-react";
import { ModalContainer, ModalTitle, ModalParagraph, ModalButtons } from "@ctouch-europe-b-v/myctouch-component-library";

const DeniedUserModal = ({ name, admin }) => {
  const { logout } = useAuth0();

  return (
    <ModalContainer className={styles.title} >
      <ModalTitle title={"It seems your access has been denied"} />
      <ModalParagraph version={"Secondary"} text={`You are not allowed to access MyCTOUCH under the organization: ${name}, by admin: ${admin}`} />
      <ModalButtons primaryButton primaryButtonText="Back" onPrimaryButtonClick={() => { logout({ logoutParams: { returnTo: window.location.origin } }) }} />
    </ModalContainer>
  );
}

export default DeniedUserModal;