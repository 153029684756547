import styles from './AccountManagementTableRow.module.css';
import { ListItem, PopUpDirection } from '@ctouch-europe-b-v/myctouch-component-library';
import ContextMenuVerifyDenyUser from '../../organisms/contextMenuVerifyDenyUser/ContextMenuVerifyDenyUser';
import { MultiSelectContext, MultiSelectDispatchContext } from '../../../../contexts/MultiSelectContext';
import { useState, useRef, useEffect, useContext } from 'react';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
};

const AccountManagementTableRow = ({ user, currentUser, setActiveModal, className }) => {
  const { height, width } = useWindowDimensions();
  const [showContext, setShowContext] = useState(false);
  const [isRowHovered, setIsRowHovered] = useState(false);
  const [contextRect, setContextRect] = useState();
  const [popupDirection, setPopupDirection] = useState(PopUpDirection.topLeft);
  const multiSelected = useContext(MultiSelectContext);
  const dispatch = useContext(MultiSelectDispatchContext);
  const [isChecked, setIsChecked] = useState(false);
  const windowSize = useRef([
    window.innerWidth,
    window.innerHeight
  ])
  const menuRef = useRef();
  useEffect(() => {
    setIsChecked(multiSelected.filter(selectedUser => selectedUser.id === user.Email).length !== 0)
  }, [multiSelected, user.Email])
  useEffect(() => {
    if (showContext) {
      const rect = menuRef.current.getBoundingClientRect();
      menuRef.current.style.left = `50%`;
      if (rect.right > (width - 50)) {
        menuRef.current.style.left = `calc(50% - ${rect.width - 2}px)`;
        setPopupDirection(PopUpDirection.topRight)
      } else if (rect.bottom > (height - 50)) {
        menuRef.current.style.top = `-${rect.height - 20}px`;
        setPopupDirection(PopUpDirection.bottomLeft)
      }

      else {
        setPopupDirection(PopUpDirection.topLeft)
      }
      setContextRect(prev => prev = rect)
    }
  }, [showContext, width])
  useOnClickOutside(menuRef, () => setShowContext(false))
  const icons = (type) => {
    switch (type) {
      case "Verified":
        return { name: "check", color: "#00BB00" }
      case "Denied":
        return { name: "xmark", color: "#EB3232" }
      case "Invited":
        return { name: "envelope", color: "#009FDA" }
      case "Pending":
        return { name: "clock", color: "#F65916" }
      default:
        return { name: "clock", color: "#009FDA" }
    }
  }

  const giveAccessHandler = () => {
    setActiveModal({ name: "SphereAccess", info: { id: user.Email, isGive: !!!user.SphereAccess } });
  }

  const selectHandler = (e) => {
    if (isChecked) {
      dispatch({ type: "Remove One", id: user.Email })
    } else {
      dispatch({ type: "Add One", id: user.Email, status: user.UserStatus })
    }
    setIsChecked(!isChecked)
  }

  const contextHandler = () => {
    setShowContext(prev => !prev);
  }

  return (
    <div onMouseEnter={() => { setIsRowHovered(true) }} onMouseLeave={() => setIsRowHovered(false)} className={[styles.tableRow, className].filter(e => !!e).join(" ")}>
      <ListItem isHovered={isRowHovered} className={[styles.tableRowItem, styles.tableItem, styles.check].filter(e => !!e).join(" ")} checked={isChecked} type={"Check"} value={user.Email} onClick={(e) => { selectHandler(e) }} />
      <ListItem isHovered={isRowHovered} className={[styles.tableRowItem, styles.tableItem].filter(e => !!e).join(" ")} type={"Text"} text={<>{user.Email} <span className={styles.activeUser}>{user.Email === currentUser.Email && '(you)'}</span></>} />
      <ListItem isHovered={isRowHovered} className={[styles.tableRowItem, styles.tableItem, styles.status].filter(e => !!e).join(" ")} type={"Icon"} iconColor={icons(user.UserStatus).color} iconName={icons(user.UserStatus).name} text={user.UserStatus !== null ? user.UserStatus : "Unknown"} />
      <ListItem isHovered={isRowHovered} isDisabled={user.UserStatus !== "Verified"} className={[styles.tableRowItem, styles.tableItem].filter(e => !!e).join(" ")} type={"Switch"} onClick={() => console.log("Clicking")} value={user.SphereAccess === 1} checked={user.SphereAccess === 1} onChange={(e) => { user.UserStatus === "Verified" && giveAccessHandler() }} />
      <ListItem isHovered={isRowHovered} className={[styles.tableRowItem, styles.tableItem, styles.menu].filter(e => !!e).join(" ")} innerRef={menuRef} type={"Menu"} onClick={() => { contextHandler() }} >
        {showContext && <ContextMenuVerifyDenyUser isVerified={user.UserStatus === "Verified" || user.UserStatus === "Invited"} isDenied={user.UserStatus === "Denied" || user.UserStatus === "Invited"} className={styles.topLeft} ref={menuRef} userId={user.Email} setActiveModal={setActiveModal} onClose={() => setShowContext(false)} popupDirection={popupDirection} />}
      </ListItem>
    </div>
  )
}

export default AccountManagementTableRow;