import { ModalContainer, ModalTitle, ModalParagraph, ModalInputField, ModalButtons } from '@ctouch-europe-b-v/myctouch-component-library';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../../../redux/user/userSlice';
import { UserService } from '../../../../services/UserService';
import styles from './UserNameModal.module.css'

const UserNameModal = () => {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user.value)
    const [firstName, setFirstName] = useState(user.FirstName);
    const [lastName, setLastName] = useState(user.LastName);
    const [email, setEmail] = useState(user.Email);

    const setUserName = () => {
        var tempUser = { ...user };
        tempUser.FirstName = firstName;
        tempUser.LastName = lastName;
        tempUser.Email = email;
        dispatch(setUser(tempUser))
        UserService.updateUser(tempUser);
    }

    return (


        <ModalContainer hasOverlay>
            <ModalTitle title={"WHAT SHOULD WE CALL YOU?"} />
            <ModalParagraph version={"Secondary"} type={"Info"} text="Hi, we’re CTOUCH. Who are you? We would like to personalize your experience." />
            <ModalInputField isRequired description={"First Name"} value={firstName} placeholder="First Name" onChange={(e) => setFirstName(e.target.value)} />
            <ModalInputField isRequired description={"Last Name"} value={lastName} placeholder="Last Name" onChange={(e) => setLastName(e.target.value)} />
            <ModalInputField className={styles.overflow} description={"Email"} isDisabled={email != null} value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
            <ModalParagraph version={"Secondary"} text="*Required" />
            <ModalButtons primaryButton primaryButtonText="Save" primaryButtonDisabled={((firstName == "" || firstName == null) || (lastName == "" || lastName == null) || email == null)} onPrimaryButtonClick={() => { setUserName() }} />

        </ModalContainer>
    )
}

export default UserNameModal;