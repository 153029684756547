import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import styles from './InputColorPicker.module.css';
import React, { useRef, useEffect, useState } from "react";

const InputColorPicker = ({ title, value, isDisabled, setValue }) => {
  const [previewValue, setPreviewValue] = useState(value)
  const [inputActive, setInputActive] = useState(false)
  useEffect(() => {
    setPreviewValue(value)
  }, [value])
  const ref = useRef()
  useOnClickOutside(ref, () => { if (inputActive) { setValue(previewValue); setInputActive(false) } })
  return (
    <div ref={ref} className={[styles.inputWrapper, isDisabled && styles.isDisabled].filter(e => !!e).join(" ")}>
      <span className={styles.inputValue}>{previewValue === undefined ? "#FFFFFF" : previewValue.toString().toUpperCase()}</span>
      <div className={styles.input}><input disabled={isDisabled} id={title.replace(" ", "-")} type="color" value={value === undefined ? "#FFFFFF" : value} onClick={() => { setInputActive(prev => !prev) }} onChange={e => setPreviewValue(e.target.value)} /></div>
    </div>
  )
}

export default InputColorPicker