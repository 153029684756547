import { MenuItem, ContextMenuContainer, ContextMenuItem, BackgroundType, PopUpDirection } from '@ctouch-europe-b-v/myctouch-component-library';
import { useEffect, useRef, useState } from 'react';
import styles from "./ProfileMenuItem.module.css"
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import PasteSettingsModal from './../pasteSettingsModal/PasteSettingsModal';
import RemoveProfileModal from '../removeProfileModal/RemoveProfileModal';
import RenameProfileModal from './../renameProfileModal/RenameProfileModal';
import { ProfileService } from './../../../../services/ProfileService';
import { useDispatch, useSelector } from 'react-redux';
import { copy } from '../../../../redux/profile/profileSlice';
import { ProfileSettingService } from '../../../../services/ProfileSettingService';
import useWindowDimensions from '../../../../hooks/useWindowsDimension';

const ProfileMenuItem = ({ profile, isSelected, onClick, onProfilesAffected, isDisabled, isDefaultProfile, onProfilePasted }) => {
    const { height, width } = useWindowDimensions();
    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false)
    const [pasteSettingsOpen, setPasteSettingsOpen] = useState(false)
    const [removeProfileOpen, setRemoveProfileOpen] = useState(false)
    const [renameProfileOpen, setRenameProfileOpen] = useState(false)
    const [popupDirection, setPopupDirection] = useState(PopUpDirection.topLeft);


    const copiedProfileId = useSelector((state) => state.profile.profileId)
    const dispatch = useDispatch()
    const ref = useRef()
    const contextRef = useRef()
    useOnClickOutside(ref, () => { if (isContextMenuOpen) { setIsContextMenuOpen(false) } })
    useEffect(() => {
        if (isContextMenuOpen) {
            const divRect = ref.current.getBoundingClientRect();
            const contextRect = contextRef.current.getBoundingClientRect();
            contextRef.current.style.left = `calc(${divRect.right}px - 30px)`;
            if (contextRect.top + contextRect.height > (height)) {
                contextRef.current.style.top = ` calc((${divRect.top}px - ${contextRect.height}px) - 40px)`;
                setPopupDirection(PopUpDirection.bottomLeft)
            } else {
                contextRef.current.style.top = `calc(${divRect.bottom}px - 8px)`
                setPopupDirection(PopUpDirection.topLeft)
            }
        }
    }, [isContextMenuOpen, height])

    const removeProfile = () => {
        ProfileService.deleteProfile(profile.Id).then(() => {
            setRemoveProfileOpen(false);
            onProfilesAffected()
        });
    }

    const pasteSettings = () => {
        if (copiedProfileId) {
            ProfileSettingService.pasteProfileSettings(copiedProfileId, profile.Id).then(() => {
                setPasteSettingsOpen(false)
            })
            onProfilePasted(profile.Id)
        }
    }

    return (
        <div className={styles.container}>
            <MenuItem isDisabled={isDisabled} isSelected={isSelected} type="Item" text={profile.ProfileName} onIconClick={() => setIsContextMenuOpen(!isContextMenuOpen)} onClick={() => onClick(profile)} />
            {isContextMenuOpen &&
                <div ref={ref} >
                    <ContextMenuContainer ref={contextRef} popupDirection={popupDirection} backgroundType={BackgroundType.grey} className={styles.position}>
                        <ContextMenuItem isDisabled={profile.ProfileName === "Default"} type="Item" text="Rename" iconName="pen" onClick={() => setRenameProfileOpen(true)} />
                        <ContextMenuItem type="Divider" />
                        <ContextMenuItem type="Item" text="Copy Settings" iconName="copy" onClick={() => { dispatch(copy(profile.Id)); setIsContextMenuOpen(false) }} />
                        <ContextMenuItem isDisabled={profile.ProfileName === "Default"} type="Item" text="Paste Settings" iconName="paste" onClick={() => setPasteSettingsOpen(true)} />
                        <ContextMenuItem type="Divider" />
                        <ContextMenuItem isDisabled={profile.ProfileName === "Default"} type="Item" version="Warning" text="Remove" iconName="trash" onClick={() => setRemoveProfileOpen(true)} />
                    </ContextMenuContainer>
                </div>
            }
            {pasteSettingsOpen && <PasteSettingsModal onPaste={() => { pasteSettings() }} onCancel={() => setPasteSettingsOpen(false)} />}
            {removeProfileOpen && <RemoveProfileModal onRemove={() => removeProfile()} onCancel={() => setRemoveProfileOpen(false)} />}
            {renameProfileOpen && <RenameProfileModal profile={profile} onSave={(e) => { ProfileService.updateProfile(e); setRenameProfileOpen(false) }} onCancel={() => setRenameProfileOpen(false)} />}
        </div>
    );
}

export default ProfileMenuItem;