import { configureStore } from "@reduxjs/toolkit";
import { userReducer, userListReducer, selectedUserReducer } from "../redux/user/userSlice"
import auth0UserReducer from "../redux/user/auth0UserSlice"
import { companyListReducer, companyReducer, selectedCompanyReducer } from "../redux/company/companySlice";
import profileReducer from "../redux/profile/profileSlice"
import { companyWithLocationsAndDisplaysReducer } from "../redux/company/companySlice";

export default configureStore({
  reducer: {
    profile: profileReducer,
    companyWithLocationsAndDisplays: companyWithLocationsAndDisplaysReducer,
    user: userReducer,
    userList: userListReducer,
    selectedUser: selectedUserReducer,
    company: companyReducer,
    companyList: companyListReducer,
    selectedCompany: selectedCompanyReducer,
    aut0User: auth0UserReducer
  },
})