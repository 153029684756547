import { useAuth0 } from "@auth0/auth0-react";
import { ModalContainer, ModalTitle, ModalParagraph, ModalButtons } from "@ctouch-europe-b-v/myctouch-component-library";

const IllegalMailModal = () => {
    const { logout } = useAuth0();

    return (
        <ModalContainer >
            <ModalTitle title={"Mail address not allowed"} />
            <ModalParagraph version={"Secondary"} text="You cannot use general email domains to create an account. Please use your organization's email domain." />
            <ModalButtons primaryButton primaryButtonText="Back" onPrimaryButtonClick={() => { logout({ logoutParams: { returnTo: window.location.origin } }) }} />
        </ModalContainer>
    );
}

export default IllegalMailModal;