import React, { useEffect, useState } from 'react';
import styles from "./SettingsPage.module.css";
import {
    Button,
    TitleBanner,
    ItemText,
    ItemTitle,
    InputField,
    Switch,
    Snackbar
} from '@ctouch-europe-b-v/myctouch-component-library'
import SettingsSubMenu from "../../ui/organisms/settingsSubMenu/SettingsSubMenu";
import { useDispatch, useSelector } from "react-redux";
import { UserService } from "../../../services/UserService";
import { ResellerCodeRequestService } from "../../../services/ResellerCodeRequestService";
import MainMenu from '../../ui/molecules/mainMenu/MainMenu';
import RequestResellerCodeModal from '../../ui/organisms/requestResellerCodeModal/RequestResellerCodeModal';
import ResellerStatus from "../../../enums/ResellerStatus";
import { CompanyService } from "../../../services/CompanyService";
import { setOneUserList, setSelectedUser, setUser } from '../../../redux/user/userSlice';
import { setCompany, setOneCompanyList, setSelectedCompany } from '../../../redux/company/companySlice';
import { SphereUserService } from '../../../services/SphereUserService';
import { useNavigate } from 'react-router-dom';

const SettingsPage = ({ auth0User }) => {
    const navigate = useNavigate()

    const dispatch = useDispatch();

    //this is object contains the fetched user info of the logged in user
    const user = useSelector((state) => state.user.value);

    //This object contains the company info of the logged in user
    const company = useSelector((state) => state.company.value);

    //This object contains the user info of the currently selected user
    const selectedUser = useSelector((state) => state.selectedUser.value);

    //This object contains the company info of the currently selected company
    const selectedCompany = useSelector((state) => state.selectedCompany.value);

    // The conditional user to be displayed
    const displayUser = selectedUser || user; // Priority goes to the selected user
    const displayCompany = selectedCompany || company; // Priority goed to the selected company

    // Role conditions + check if something is selected
    const isReseller = user?.Roles?.some(item => item?.Name === "Reseller");
    const isAdmin = user?.Roles?.some(item => item?.Name === "Administrator");
    const isCTOUCH = user?.Roles?.some(item => item?.Name === "CTOUCH");
    const isUserSelected = selectedUser && user?.Email !== selectedUser?.Email;
    const isCompanySelected = selectedCompany && company?.id !== selectedCompany?.id;

    //Conditions for the setting blocks which can be shown and which don't
    const showAboutYou = user != null && (!isReseller || !isCompanySelected && (!isUserSelected || isUserSelected));
    const showExternalAccess = isAdmin && !isUserSelected;
    const showCredential = user != null && (!isReseller || (isReseller && !isCompanySelected && !isUserSelected) || isUserSelected);
    const showResellerAccount = (isAdmin || isReseller) && !isUserSelected && !isCompanySelected;
    const showNothing = (isReseller || isCTOUCH) && selectedCompany && !selectedUser

    //Name change form state
    const [firstName, setFirstName] = useState(displayUser.FirstName);
    const [lastName, setLastName] = useState(displayUser.LastName);

    //input fields name active state
    const [firstNameFieldActive, setFirstNameFieldActive] = useState(false);
    const [lastNameFieldActive, setLastNameFieldActive] = useState(false);

    // Save Button for Names enabled state
    const [saveNamesButtonEnabled, setSaveNamesButtonEnabled] = useState(false)

    const [ctouchSwitchDisabled, setCtouchSwitchDisabled] = useState(false)

    //Show the snackbar state
    const [showSnack, setShowSnack] = useState(false);

    //State of the Error snackbar
    const [showErrorSnack, setShowErrorSnack] = useState([false])

    //State of Password reset email snackbar
    const [showPassSnack, setShowPassSnack] = useState(false)

    //State for save button of for reseller code
    const [showSaveResellerBtn, setShowSaveResellerBtn] = useState(false);

    //State for check if the user has requested a reseller code
    const [resellerCodeRequested, setResellerCodeRequested] = useState(false);

    //State for reseller code form
    const [resellerCodeFormActive, setResellerCodeFormActive] = useState(false);

    //State currentUsers Reseller Code
    const [resellerCode, setResellerCode] = useState("")

    //State for showing the reseller modal
    const [showResellerCodeModal, setShowResellerCodeModal] = useState(false);

    //State for the input field state
    const [resellerCodeFormInputState, setResellerCodeFormInputState] = useState("Default")

    //State for the name of the Reseller Organization 
    const [resellerCompanyName, setResellerCompanyName] = useState(null)

    //State for what to show in the reseller code modal
    let [resellerState, setResellerState] = useState(ResellerStatus.newRequest)

    const getDealerCodeRequest = async () => {
        if (displayCompany.ExternalToUserCode !== null || (typeof displayCompany.ExternalToUserCode === "string" && displayCompany.ExternalToUserCode !== "null")) {
            setResellerState(ResellerStatus.Approved);
            return;
        }

        ResellerCodeRequestService.getResellerCodeRequest(displayCompany.Id).then(async (responseReseller) => {
            setResellerState(ResellerStatus.PendingRequest);

            if (responseReseller.IsDenied) {
                setResellerState(ResellerStatus.Denied);
                return;
            }
        }).catch((error) => {
            if (error.response?.status === 400 && error.response.data[0] === "Dealer code request was not found.") {
                setResellerState(ResellerStatus.NewRequest);
            }
        });
    }

    useEffect(() => {

        if (!isCompanySelected && !isUserSelected) {
            getDealerCodeRequest();
        }
    }, [isCompanySelected, isUserSelected]);

    useEffect(() => {
        //To fill in the name form
        setFirstName(displayUser.FirstName)
        setLastName(displayUser.LastName)

        //Check if the user has a reseller code or has requested a reseller code
        checkResellerCodeAndRequest()
    }, [displayUser]);

    // Check if first and last name are filled in, then enable save button
    useEffect(() => {
        if ((firstName !== null && lastName !== null)
            && (firstName !== displayUser.FirstName || lastName !== displayUser.LastName)
            && (firstName.trim() !== "") && (lastName.trim() !== "")) {
            setSaveNamesButtonEnabled(true)
        } else {
            setSaveNamesButtonEnabled(prev => prev ? false : prev)
        }
    }, [firstName, lastName])

    //Check function to check if the user has a reseller code or has requested a reseller code
    async function checkResellerCodeAndRequest() {
        if (company) {
            if (!company.ExternalManagementConnectCode || typeof company.ExternalManagementConnectCode == "string" && company.ExternalManagementConnectCode === "null") {
                setResellerCodeFormActive(false)
                setResellerCode(null)
            } else {
                await CompanyService.getResellerCompany(company.ExternalManagementConnectCode).then((res) => setResellerCompanyName(res.Name))
                setResellerCode(company.ExternalManagementConnectCode);
                setResellerCodeFormActive(true)
            }
        }
    }

    //OnChange for first name
    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };

    //OnChange for last name
    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    };

    const handleErrorSnackbarClose = () => {
        setShowErrorSnack(prev => prev = [false, prev[1]])
    }

    //Handle snackbar close function
    const handleSnackbarClose = () => {
        setShowSnack(false)
    };

    const removeResellerCode = () => {
        CompanyService.updateCompanyResellerCode(displayCompany.Id, resellerCode).then(() => {
            setResellerCodeFormActive(false)
            setResellerCompanyName(null)
        }).catch(error => {
            setShowErrorSnack([true, "Something went wrong, please try again later"])
        })
    }

    //OnChange for reseller code
    const handleResellerCodeChange = (event) => {
        setResellerCodeFormInputState("Default")
        setResellerCode(event.target.value === "" || event.target.value.length === 0 ? null : event.target.value);
        if (event.target.value !== resellerCode) { // are there some rules for the dealer codes, such as minimum length

            setShowSaveResellerBtn(true);
        } else {
            setShowSaveResellerBtn(false)
        }
    };

    //Function to save new name for user
    const handleNameChange = async () => {
        const newUser = {
            "email": displayUser.Email,
            "companyId": displayUser.CompanyId,
            "verifiedByAdmin": displayUser.VerifiedByAdmin,
            "isAdmin": displayUser.IsAdmin,
            "isDenied": displayUser.IsDenied,
            "firstName": firstName,
            "lastName": lastName,
            "sphereAccess": displayUser.SphereAccess,
            "userStatus": displayUser.UserStatus
        }
        //TODO standardized data object like models in C#
        UserService.updateUser(newUser).then((response) => {
            if (displayUser.Email === user.Email) {
                var tempUser = { ...user }
                tempUser.FirstName = firstName
                tempUser.LastName = lastName
                dispatch(setUser(tempUser))
                dispatch(setOneUserList(tempUser))
            } else {
                var tempUser = { ...selectedUser }
                tempUser.FirstName = firstName
                tempUser.LastName = lastName
                dispatch(setSelectedUser(tempUser))
                dispatch(setOneUserList(tempUser))
            }
            setShowSnack(true)
        }).catch(error => {
            if (error.response.data[0].startsWith("Data too long for column")) {
                setShowErrorSnack([true, `${error.response.data[0].split("'")[1].split("_").map(str => `${str.charAt(0).toUpperCase()}${str.substring(1, str.length)}`).join(" ")} has too many characters. 100 characters max`]);
            }
        })
    };

    const handlePasswordChange = () => {
        const id = auth0User.sub;
        SphereUserService.changePassword({ authUserID: auth0User.sub }).then((res) => {
            window.open(res.data.ticket)
        })
    }

    //Function to save the new reseller code
    const handleResellerCodeInput = async () => {
        if (resellerCode === null || resellerCode.length === 0 || resellerCode === "") {
            removeResellerCode()
        } else {
            await CompanyService.getResellerCompany(resellerCode).then((response) => {
                if (response.Name !== undefined) {
                    setResellerCompanyName(response.Name);
                    setResellerCodeFormInputState("Correct");
                }
                CompanyService.updateCompanyResellerCode(displayCompany.Id, resellerCode).catch(error => {
                    setShowErrorSnack([true, "Something went wrong, please try again later"])
                })
            }).catch((error) => {
                const errorStatus = error.response !== undefined && error.response.status
                if (errorStatus && errorStatus === 400 && error.response.data[0] === "Company cannot be found") {
                    setResellerCodeFormInputState("Warning")
                }
            })
        }
    };

    const handleCtouchSwitchChange = (event) => {
        const checked = event.target.checked;
        CompanyService.updateCompanyCTOUCHSupport(displayCompany.Id, checked).then((res) => {
            let tempCompany = { ...displayCompany };
            tempCompany.CtouchSupport = checked;
            if (selectedCompany === null) {
                dispatch(setCompany(tempCompany))
            } else {
                dispatch(setSelectedCompany(tempCompany))
            }
            dispatch(setOneCompanyList(tempCompany))
            setCtouchSwitchDisabled(false)
        }).catch(error => {
            const errorStatus = error?.status !== undefined && error.response.status;
            if (errorStatus && errorStatus === 400) {
                setShowErrorSnack([true, "Something went wrong, please try again later"])
            }
            setCtouchSwitchDisabled(false)
        })
    }

    const handleResellerSwitchChange = (event) => {
        if (!event.target.checked) {
            setResellerCode(null)
            removeResellerCode()
        }
        setResellerCodeFormActive(event.target.checked)
    }

    const renderResellerText = () => {
        switch (resellerState) {
            case ResellerStatus.NewRequest:
            case ResellerStatus.PendingRequest:
                return <ItemText text={"Request a reseller code to manage other accounts (organizations)."} />
            case ResellerStatus.Denied:
                return <ItemText text={"Request a reseller code to manage other accounts (organizations). Your previous request was denied. If you think this was wrong, please contact us."} />
            case ResellerStatus.Approved:
                return <ItemText text={"Share this code with your customers so they can give you external access."} />
        }
    };

    const renderResellerActions = () => {
        switch (resellerState) {
            case ResellerStatus.NewRequest:
            case ResellerStatus.PendingRequest:
                return <Button
                    type={resellerState !== ResellerStatus.PendingRequest ? "Primary" : "Disabled"}
                    text={resellerState !== ResellerStatus.PendingRequest ? "Request code" : "Pending..."}
                    isDisabled={resellerState === ResellerStatus.PendingRequest}
                    onClick={() => setShowResellerCodeModal(true)} />;
            case ResellerStatus.Denied:
                return <>
                    <Button
                        type={"Disabled"}
                        text={"Request code"}
                        isDisabled={true} />
                    <Button
                        type={"Primary"}
                        text={"Contact us"}
                        onClick={() => window.open("https://support.ctouch.eu/hc/en-us", "_blank")} />
                </>
            case ResellerStatus.Approved:
                return <InputField backgroundType="Grey" placeholder={"Code"} type={"Text"} state={"Disabled"} title={"Reseller Code"} withTitle={true} inputValue={company.ExternalManagementConnectCode} />
        }
    }

    //Formatting the title of the Reseller Input field 
    const formatResellerInputText = () => {
        if (resellerCompanyName === null) {
            return 'Reseller Code';
        } else {
            return <>Reseller Code - <strong>{resellerCompanyName}</strong></>
        }
    }

    //Formatting for the text underneath credentials
    const formatLinkEnd = (text) => {
        return (
            <>
                {text}
                <span className={styles.passwordChangeLink} onClick={() => handlePasswordChange()}>here</span>
                {"."}
            </>
        );
    };

    return (
        <>
            <div className={styles.page}>
                <MainMenu />
                <div className={styles.submenu}>
                    <SettingsSubMenu user={user} />
                </div>
                <div className={styles.banner}>
                    <TitleBanner color="White" title="Settings"
                        subtitle="Change your preferences" imageUrl="" />
                </div>
                {showNothing ? <div className={styles.content}>
                    Nothing to see here
                </div>
                    : (
                        <div className={styles.content}>
                            {showAboutYou && (<div className={styles.settingContainer}>
                                <div className={styles.textContainer}>
                                    <ItemTitle title={"About you"} />
                                    <br />
                                    <ItemText text={"What may we call you?"} />
                                </div>
                                <div className={styles.settingBlock}>
                                    <InputField backgroundType="Grey" inputValue={firstName !== null ? firstName : undefined} placeholder={"First Name"} type={"Text"} title={"First Name"}
                                        withTitle={true} onChange={handleFirstNameChange}
                                        onClick={() => setFirstNameFieldActive(true)} onBlur={() => setFirstNameFieldActive(false)} state={firstNameFieldActive ? "Active" : "Empty"}
                                    />
                                    <InputField backgroundType="Grey" inputValue={lastName !== null ? lastName : undefined} placeholder={"Last Name"} type={"Text"} title={"Last Name"}
                                        withTitle={true} onChange={handleLastNameChange}
                                        onClick={() => setLastNameFieldActive(true)} onBlur={() => setLastNameFieldActive(false)} state={lastNameFieldActive ? "Active" : "Empty"}
                                    /><InputField backgroundType="Grey" inputValue={displayCompany.Name} type={"Text"}
                                        title={"Organization"}
                                        withTitle={true} state={"Disabled"}
                                        className={styles.bigInputContainer} />
                                    <div className={styles.buttonContainer}>
                                        {saveNamesButtonEnabled
                                            ? <Button type={"Primary"} text={"Save"} onClick={handleNameChange} />
                                            : <Button type={"Disabled"} text={"Save"} />
                                        }
                                        <Snackbar open={showSnack} onClose={handleSnackbarClose}
                                            text={formatNameChangeSnackbar(firstName, lastName)} />

                                    </div>
                                </div>
                            </div>
                            )}
                            {showCredential && (
                                <div className={styles.settingContainer}>
                                    <div className={styles.textContainer}>
                                        <ItemTitle title={"Credentials"} />
                                        <br />
                                        {displayUser.sso ?
                                            <ItemText
                                                text={"Your account uses Single Sign On (SSO). You can change your credentials at the account provider."} />
                                            :
                                            selectedUser !== null ?
                                                <ItemText
                                                    text={"You can't change the credentials of other accounts."} />
                                                :
                                                <>
                                                    <ItemText text={formatLinkEnd("You can change your password ")} />
                                                </>
                                        }
                                    </div>

                                    <div className={styles.settingBlock}>
                                        <InputField backgroundType="Grey" inputValue={displayUser.Email} type={"Text"} title={"Email"}
                                            withTitle={true}
                                            state={"Disabled"} className={styles.bigInputContainer} />
                                        <InputField backgroundType="Grey" inputValue={"**********"} type={"Password"} title={"Password"}
                                            withTitle={true}
                                            state={"Disabled"} />
                                    </div>
                                    <Snackbar open={showPassSnack} onClose={handleSnackbarClose} text={"Change password email sent"} />
                                </div>
                            )}
                            {showExternalAccess && (
                                <div className={styles.settingContainer}>
                                    <div className={styles.textContainer}>
                                        <ItemTitle title={"External acces"} />
                                        <br />
                                        <ItemText
                                            text={formatRecommended("Give CTOUCH and/or your reseller access to provide support. ")} />
                                    </div>
                                    <div className={styles.settingBlock}>
                                        <Switch withTitle={true} title={"CTOUCH"} isDisabled={ctouchSwitchDisabled} checked={displayCompany.CtouchSupport} value={displayCompany.CtouchSupport}
                                            onChange={(e) => { setCtouchSwitchDisabled(true); handleCtouchSwitchChange(e) }} />
                                        {!isReseller ? (
                                            <>
                                                <Switch withTitle={true} title={"Reseller"} checked={resellerCodeFormActive} onChange={handleResellerSwitchChange} />
                                                {resellerCodeFormActive ?
                                                    <InputField className={styles.resellerInput} backgroundType="Grey" placeholder={"Code"} type={"Text"}
                                                        title={formatResellerInputText()}
                                                        withTitle={true}
                                                        state={resellerCodeFormInputState}
                                                        onChange={handleResellerCodeChange}
                                                        inputValue={resellerCode}
                                                    /> : <InputField backgroundType="Grey" placeholder={"Code"} type={"Text"}
                                                        title={"Reseller Code"}
                                                        state={"Disabled"}
                                                        withTitle={true}
                                                        inputValue={resellerCode === null ? undefined : resellerCode}
                                                    />}
                                                <div className={styles.buttonContainerSave}>
                                                    {showSaveResellerBtn && resellerCodeFormActive ? (
                                                        <Button type={"Primary"} text={"Save"}
                                                            onClick={handleResellerCodeInput} />
                                                    ) : <Button type={"Disabled"} isDisabled={true} text={"Save"} />}
                                                </div>
                                            </>) : null}
                                    </div>
                                </div>
                            )}

                            {showResellerAccount && (
                                <div className={styles.settingContainer}>
                                    <div className={styles.textContainer}>
                                        <ItemTitle title={"Reseller account"} />
                                        <br />
                                        {renderResellerText()}
                                    </div>

                                    <div className={styles.resellerActionBlock}>
                                        {renderResellerActions()}
                                    </div>
                                </div>
                            )}
                        </div>)}
            </div>
            <Snackbar open={showErrorSnack[0]} onClose={handleErrorSnackbarClose}
                text={showErrorSnack[1]} />
            {showResellerCodeModal && <RequestResellerCodeModal requestCompany={company} onCancel={() => { setShowResellerCodeModal(false); getDealerCodeRequest(); }} company={company} userEmail={displayUser.Email}></RequestResellerCodeModal>}
        </>
    );
}



//Formatting for the text underneath external acces
const formatRecommended = (text) => {
    return (
        <div className={styles.recommendedTextContainer}>
            {text}&nbsp;
            <div className={styles.recommendedText}>(Recommended)</div>
        </div>
    );
};

//Formatting for name snackbar
const formatNameChangeSnackbar = (firstName, lastName) => {
    return (
        <>your settings were updated. Hi <strong>{firstName} {lastName}</strong></>
    );
};

export default SettingsPage;
