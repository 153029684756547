import styles from "./RequestResellerCodeModal.module.css";
import { ModalContainer, ModalTitle, ModalParagraph, ModalButtons } from "@ctouch-europe-b-v/myctouch-component-library"
import { MailService } from "../../../../services/MailService";
import { ResellerCodeRequestService } from "../../../../services/ResellerCodeRequestService";

const RequestResellerCodeModal = ({ onCancel, company, userEmail }) => {
    const handleRequestCode = () => {
        MailService.sentRequestResellerCode(userEmail, company.Id);
        ResellerCodeRequestService.createResellerCodeRequest({
            CompanyId: company.Id,
            RequestDate: new Date(),
            IsDenied: 0,
            UserEmail: userEmail
        }).then(() => {
            onCancel();
        });
    }

    return (
        <ModalContainer hasOverlay>
            <ModalTitle title={"Reseller Account"} />
            <ModalParagraph className={styles.bold} color={"#666"} type="Info" text={"You are about to request a reseller code. This means your organization will be registered as a reseller after approval. Please cancel if this is not correct. Are you sure?"} />
            <ModalButtons
                primaryButton
                cancelButton
                primaryButtonText="Request Code"
                onPrimaryButtonClick={() => handleRequestCode()}
                onCancelButtonClick={onCancel}
                primaryButtonType="Primary" />
        </ModalContainer>
    )
}

export default RequestResellerCodeModal;