import styles from './AccountManagementTableHeader.module.css';
import { ListItem, CheckBox } from '@ctouch-europe-b-v/myctouch-component-library';
import { MultiSelectContext, MultiSelectDispatchContext } from '../../../../contexts/MultiSelectContext';
import { useContext, useEffect, useState } from 'react';

const AccountManagementTableHeader = ({ activeUserHandler, className, users }) => {
  const multiSelected = useContext(MultiSelectContext);
  const dispatch = useContext(MultiSelectDispatchContext);
  const [allChecked, setAllChecked] = useState(multiSelected.length === users.length);

  useEffect(() => {
    setAllChecked(multiSelected.length === users.length && users.length > 0)
  }, [multiSelected, users])

  const selectAllHandler = (e) => {
    if (!allChecked) {
      const usersInfo = [];
      users.forEach(user => {
        usersInfo.push({ id: user.Email, status: user.UserStatus })
      })
      dispatch({
        type: "Add All",
        users: usersInfo
      })
    } else {
      dispatch({ type: "Remove All" })
    }
    setAllChecked(!allChecked)

  }
  return (
    <div className={[styles.tableHeader, className].filter(e => !!e).join(" ")}>
      <ListItem className={[styles.tableHeaderItem, styles.headerCheckBox].filter(e => !!e).join(" ")} type={"Title"} text={<CheckBox checked={allChecked} onClick={(e) => { selectAllHandler(e) }} />} />
      <ListItem className={styles.tableHeaderItem} type={"Title"} text={"Email"} />
      <ListItem className={styles.tableHeaderItem} type={"Title"} text={"Status"} />
      <ListItem className={[styles.center, styles.tableHeaderItem].filter(e => !!e).join(" ")} type={"Title"} text={"Sphere Access"} />
      <ListItem className={[styles.center, styles.tableHeaderItem].filter(e => !!e).join(" ")} type={"Title"} text={"Actions"} />
    </div>
  )
}

export default AccountManagementTableHeader