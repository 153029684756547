import styles from './LoadingPage.module.css';
import MainMenu from "../../ui/molecules/mainMenu/MainMenu";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LoadingPage = ({ }) => {
  return (
    <div className={styles.container}>
      <MainMenu className={styles.mainMenu} defaultVersion />
      <div className={styles.page}>
        <span className={styles.loading}><FontAwesomeIcon icon={["fas", "spinner"]} /></span>
      </div>
    </div>
  )
}

export default LoadingPage