import { ModalContainer, ModalTitle, ModalParagraph, ModalButtons, ModalInputField, ModalDivider } from "@ctouch-europe-b-v/myctouch-component-library";
import { useState } from "react";
import { SphereCompanyService } from "../../../../services/SphereCompanyService";
import { SphereUserService } from "../../../../services/SphereUserService";
import styles from './SetupAdminModal.module.css';
const countryList =
    [
        { text: "Albania" },
        { text: "Algeria" },
        { text: "Andorra" },
        { text: "Angola" },
        { text: "Antigua and Barbuda" },
        { text: "Argentina" },
        { text: "Armenia" },
        { text: "Australia" },
        { text: "Austria" },
        { text: "Azerbaijan" },
        { text: "Bahamas" },
        { text: "Bahrain" },
        { text: "Bangladesh" },
        { text: "Barbados" },
        { text: "Belarus" },
        { text: "Belgium" },
        { text: "Belize" },
        { text: "Benin" },
        { text: "Bhutan" },
        { text: "Bolivia" },
        { text: "Bosnia and Herzegovina" },
        { text: "Botswana" },
        { text: "Brazil" },
        { text: "Brunei" },
        { text: "Bulgaria" },
        { text: "Burkina Faso" },
        { text: "Burundi" },
        { text: "Cabo Verde" },
        { text: "Cambodia" },
        { text: "Cameroon" },
        { text: "Canada" },
        { text: "Central African Republic (CAR)" },
        { text: "Chad" },
        { text: "Chile" },
        { text: "China" },
        { text: "Colombia" },
        { text: "Comoros" },
        { text: "Democratic Republic of the Congo" },
        { text: "Republic of the Congo" },
        { text: "Costa Rica" },
        { text: "Cote d Ivoire" },
        { text: "Croatia" },
        { text: "Cuba" },
        { text: "Cyprus" },
        { text: "Czech Republic" },
        { text: "Denmark" },
        { text: "Djibouti" },
        { text: "Dominica" },
        { text: "Dominican Republic" },
        { text: "Ecuador" },
        { text: "Egypt" },
        { text: "El Salvador" },
        { text: "Equatorial Guinea" },
        { text: "Eritrea" },
        { text: "Estonia" },
        { text: "Ethiopia" },
        { text: "Fiji" },
        { text: "Finland" },
        { text: "France" },
        { text: "Gabon" },
        { text: "Gambia" },
        { text: "Georgia" },
        { text: "Germany" },
        { text: "Ghana" },
        { text: "Greece" },
        { text: "Grenada" },
        { text: "Guatemala" },
        { text: "Guinea" },
        { text: "Guinea-Bissau" },
        { text: "Guyana" },
        { text: "Haiti" },
        { text: "Honduras" },
        { text: "Hungary" },
        { text: "Iceland" },
        { text: "India" },
        { text: "Indonesia" },
        { text: "Iran" },
        { text: "Iraq" },
        { text: "Ireland" },
        { text: "Israel" },
        { text: "Italy" },
        { text: "Jamaica" },
        { text: "Japan" },
        { text: "Jordan" },
        { text: "Kazakhstan" },
        { text: "Kenya" },
        { text: "Kiribati" },
        { text: "Kosovo" },
        { text: "Kuwait" },
        { text: "Kyrgyzstan" },
        { text: "Laos" },
        { text: "Latvia" },
        { text: "Lebanon" },
        { text: "Lesotho" },
        { text: "Liberia" },
        { text: "Libya" },
        { text: "Liechtenstein" },
        { text: "Lithuania" },
        { text: "Luxembourg" },
        { text: "Macedonia (FYROM)" },
        { text: "Madagascar" },
        { text: "Malawi" },
        { text: "Malaysia" },
        { text: "Maldives" },
        { text: "Mali" },
        { text: "Malta" },
        { text: "Marshall Islands" },
        { text: "Mauritania" },
        { text: "Mauritius" },
        { text: "Mexico" },
        { text: "Micronesia" },
        { text: "Moldova" },
        { text: "Monaco" },
        { text: "Mongolia" },
        { text: "Montenegro" },
        { text: "Morocco" },
        { text: "Mozambique" },
        { text: "Myanmar (Burma)" },
        { text: "Namibia" },
        { text: "Nauru" },
        { text: "Nepal" },
        { text: "Netherlands" },
        { text: "New Zealand" },
        { text: "Nicaragua" },
        { text: "Niger" },
        { text: "Nigeria" },
        { text: "North Korea" },
        { text: "Norway" },
        { text: "Oman" },
        { text: "Pakistan" },
        { text: "Palau" },
        { text: "Palestine" },
        { text: "Panama" },
        { text: "Papua New Guinea" },
        { text: "Paraguay" },
        { text: "Peru" },
        { text: "Philippines" },
        { text: "Poland" },
        { text: "Portugal" },
        { text: "Qatar" },
        { text: "Romania" },
        { text: "Russia" },
        { text: "Rwanda" },
        { text: "Saint Kitts and Nevis" },
        { text: "Saint Lucia" },
        { text: "Saint Vincent and the Grenadines" },
        { text: "Samoa" },
        { text: "San Marino" },
        { text: "Sao Tome and Principe" },
        { text: "Saudi Arabia" },
        { text: "Senegal" },
        { text: "Serbia" },
        { text: "Seychelles" },
        { text: "Sierra Leone" },
        { text: "Singapore" },
        { text: "Slovakia" },
        { text: "Slovenia" },
        { text: "Solomon Islands" },
        { text: "Somalia" },
        { text: "South Africa" },
        { text: "South Korea" },
        { text: "South Sudan" },
        { text: "Spain" },
        { text: "Sri Lanka" },
        { text: "Sudan" },
        { text: "Suriname" },
        { text: "Swaziland" },
        { text: "Sweden" },
        { text: "Switzerland" },
        { text: "Syria" },
        { text: "Taiwan" },
        { text: "Tajikistan" },
        { text: "Tanzania" },
        { text: "Thailand" },
        { text: "Timor-Leste" },
        { text: "Togo" },
        { text: "Tonga" },
        { text: "Trinidad and Tobago" },
        { text: "Tunisia" },
        { text: "Turkey" },
        { text: "Turkmenistan" },
        { text: "Tuvalu" },
        { text: "Uganda" },
        { text: "Ukraine" },
        { text: "United Arab Emirates (UAE)" },
        { text: "United Kingdom (UK)" },
        { text: "United States of America (USA)" },
        { text: "Uruguay" },
        { text: "Uzbekistan" },
        { text: "Vanuatu" },
        { text: "Vatican City (Holy See)" },
        { text: "Venezuela" },
        { text: "Vietnam" },
        { text: "Yemen" },
        { text: "Zambia" },
        { text: "Zimbabwe" },
    ]

const branches = [{ text: "Education" }, { text: "Business" }]

const SetupAdminModal = ({ setPage, user, setCompany }) => {
    const [name, setName] = useState(null);
    const [country, setCountry] = useState(countryList[0].text);
    const [branch, setBranch] = useState(branches[0].text);
    const [premiumChecked, setPremiumChecked] = useState(false)
    const [marketingChecked, setMarketingChecked] = useState(false)

    const handleSetupAdmin = () => {
        let company = { "companyName": name, "country": country, "branch": branch, "marketing": marketingChecked, "CTOUCH_support": 0, "mail": user.email };



        SphereCompanyService.createCompany(company).then((response) => {
            setCompany(company)
            SphereUserService.update({ email: user.email, company_id: response.data.id, is_admin: 1, verified_by_admin: 1, user_status: "Verified", sphere_access: "1" }).then(() => {
                setPage(4)
            })
        });
    }

    const formatPremiumContentText = () => {
        return <>I allow CTOUCH to store and process my data to deliver high-quality service and provide relevant product and service related updates, as further detailed in our <a href="https://ctouch.eu/privacy-statement" target="_blank" className={styles.link}>privacy statement</a>.</>
    }

    return (
        <ModalContainer >

            <ModalTitle title={"Organization setup"} />
            <ModalParagraph version={"Secondary"} text="Tell us about your organization. Don’t worry, we’ll be done in a minute." />
            <ModalInputField isRequired description={"Name"} value={name} placeholder="Organization Name" onChange={(e) => setName(e.target.value)} />
            <ModalInputField isRequired className={styles.dropdownInput} description={"Country"} dropdownProp={['text']} type={"Dropdown"} options={countryList} value={country} onChange={(e) => { setCountry(e.target.value) }} />
            <ModalInputField isRequired className={styles.dropdownInput} description={"Branch"} dropdownProp={['text']} options={branches} type={"Dropdown"} value={branch} onChange={(e) => { setBranch(e.target.value) }} />

            <ModalDivider />
            <ModalInputField value={"Sign me up for the newsletter. (You can unsubscribe at any time)."} type={"Check"} checked={marketingChecked} onChange={(e) => { setMarketingChecked(e) }} />
            <ModalInputField value={formatPremiumContentText()} type={"Check"} checked={premiumChecked} onChange={(e) => { setPremiumChecked(e) }} />
            <ModalButtons primaryButton primaryButtonText="Save" primaryButtonDisabled={((name == null || name.length <= 0) || branch == null || country == null || !premiumChecked)} onPrimaryButtonClick={() => { handleSetupAdmin() }} />
        </ModalContainer>
    );
}

export default SetupAdminModal;