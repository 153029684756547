import styles from './ColorPicker.module.css';
import InputColorPicker from './../../atoms/inputColorPicker/InputColorPicker'
import { InputLabel } from "@ctouch-europe-b-v/myctouch-component-library"
const ColorPicker = ({ title, value, isDisabled, setValue }) => {

  return (
    <div className={styles.container}>
      <InputLabel title={title} />
      <InputColorPicker isDisabled={isDisabled} title={title} value={value} setValue={setValue} />
    </div>
  )
}

export default ColorPicker;