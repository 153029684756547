import { useDispatch, useSelector } from 'react-redux';
import { UserService } from '../../../../services/UserService';
import styles from './ModalRemoveUser.module.css';
import { ModalContainer, ModalParagraph, ModalTitle, ModalButtons, ButtonType } from '@ctouch-europe-b-v/myctouch-component-library';
import { setUserList } from '../../../../redux/user/userSlice';

const ModalRemoveUser = ({ userId, setActiveModal }) => {
  const userList = useSelector(state => state.userList.value);
  const dispatch = useDispatch();
  const handleDeleteUser = async () => {
    UserService.deleteUser(userId).then(() => {
      const newUserList = userList.filter(item => item.Email !== userId);
      dispatch(setUserList(newUserList));
      setActiveModal("none")
    })
  }
  return (
    <ModalContainer hasOverlay className={styles.height} onClick={() => setActiveModal("none")}>
      <ModalTitle title={"Remove User"} />
      <ModalParagraph className={styles.text} text={"Are you sure you want to remove this user? This action cannot be undone"} type={"Info"} version={"Secondary"} />
      <ModalButtons cancelButton cancelButtonType={ButtonType.secondaryOutline} onCancelButtonClick={() => setActiveModal("none")} primaryButton primaryButtonText={"Remove User"} primaryButtonType={ButtonType.danger} onPrimaryButtonClick={() => handleDeleteUser()} />
    </ModalContainer>
  )
}

export default ModalRemoveUser;