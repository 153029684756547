import { UserService } from "../services/UserService";
import { setOneUserList, setUser } from '../redux/user/userSlice';
import { useDispatch, useSelector } from "react-redux";

const useUpdateUser = () => {
  const dispatch = useDispatch();
  const userList = useSelector((state) => state.userList.value);
  const activeUser = useSelector((state) => state.user.value)
  const handler = async (prop, newValue, userId) => {
    return new Promise((resolve, reject) => {
      let newUser = JSON.parse(JSON.stringify(userList.filter(item => item.Email === userId)));
      newUser[0][prop] = newValue
      if (prop === "SphereAccess") {
        newUser[0].VerifiedByAdmin = newValue
      }
      if (prop === "UserStatus" && newValue === "Denied") {
        newUser[0].SphereAccess = 0
        newUser[0].VerifiedByAdmin = 0
      }
      if (prop === "UserStatus" && newValue === "Verified" && newUser[0].SphereAccess === 1) {
        newUser[0].VerifiedByAdmin = 1
      }
      if (newUser[0].Email === activeUser.Email) {
        const newActiveUser = JSON.parse(JSON.stringify(activeUser));

        newActiveUser[prop] = newValue;
        if (prop === "UserStatus" && newValue === "Denied") {
          newActiveUser.SphereAccess = 0
          newActiveUser.VerifiedByAdmin = 0
        }
        if (prop === "UserStatus" && newValue === "Verified" && newActiveUser.SphereAccess === 1) {
          newActiveUser.VerifiedByAdmin = 1
        }
        dispatch(setUser(newActiveUser))
      }
      UserService.updateUser(newUser[0]).then((response) => {
        if (response.status === 404) { reject() }
        dispatch(setOneUserList(response))
        resolve()
      })
    })

  }
  return handler
}

export default useUpdateUser