
import { AxiosService } from './AxiosService';
const baseUrl = "sphere/companies/user";
const axiosService = AxiosService(process.env.REACT_APP_SPHERE_API_URL)

export const SphereUserService = {
    async create(user) {
        return await axiosService.postRequest(`${baseUrl}/create`, user);
    },
    async update(user) {
        return await axiosService.postRequest(`${baseUrl}/update`, user);
    },
    async changePassword(user) {
        return await axiosService.postRequest(`myCTOUCH/Roles/changePassword`, user)
    }
};