import { createSlice } from "@reduxjs/toolkit";

export const companySlice = createSlice({
    name: 'company',
    initialState: {
        value: null
    },
    reducers: {
        setCompany: (state, newCompany) => {
            state.value = newCompany.payload
        }
    }
})

export const companyListSlice = createSlice({
    name: 'companyList',
    initialState: {
        value: null
    },
    reducers: {
        setCompanyList: (state, newCompanies) => {
            state.value = newCompanies.payload
        },
        setOneCompanyList: (state, newCompany) => {
            let newState = JSON.parse(JSON.stringify(state.value))
            newState = newState.filter(oldCompanies => { return oldCompanies.Id !== newCompany.payload.Id });;
            newState = [...newState, newCompany.payload];
            state.value = newState
        }
    }
})

export const selectedCompanySlice = createSlice({
    name: 'selectedCompany',
    initialState: {
        value: null
    },
    reducers: {
        setSelectedCompany: (state, newCompany) => {
            state.value = newCompany.payload
        }
    }
})

export const companyWithLocationsAndDisplaysSlice = createSlice({
    name: 'companyWithLocationsAndDisplays',
    initialState: {
        value: null
    },
    reducers: {
        setCompanyWithLocationsAndDisplays: (state, newCompany) => {
            state.value = newCompany.payload
        }
    }
})

export const { setCompany } = companySlice.actions
export const { setCompanyList, setOneCompanyList } = companyListSlice.actions
export const { setSelectedCompany } = selectedCompanySlice.actions
export const { setCompanyWithLocationsAndDisplays } = companyWithLocationsAndDisplaysSlice.actions

export const companyReducer = companySlice.reducer
export const selectedCompanyReducer = selectedCompanySlice.reducer
export const companyListReducer = companyListSlice.reducer
export const companyWithLocationsAndDisplaysReducer = companyWithLocationsAndDisplaysSlice.reducer
