import { createSlice } from "@reduxjs/toolkit";

export const auth0UserSlice = createSlice({
  name: 'auth0User',
  initialState: {
    value: null
  },
  reducers: {
    setAuth0User: (state, newUser) => {
      state.value = newUser.payload
    }
  }

})

export const { setAuth0User } = auth0UserSlice.actions

export default auth0UserSlice.reducer