import { useEffect, useState } from "react";
import styles from "./SlaChecker.module.css";
import {
    TitleBanner,
    ItemTitle,
    InputField,
    Popup,
    PopUpDirection
} from '@ctouch-europe-b-v/myctouch-component-library'
import MainMenu from '../../ui/molecules/mainMenu/MainMenu';
import SlaSubMenu from "../../ui/organisms/slaSubMenu/SlaSubMenu";
import { SlaService } from "../../../services/SlaService";

const SlaChecker = () => {
    const [slaData, setSlaData] = useState({});
    const [popUpText, setPopUpText] = useState(null);
    const [searchSerial, setSearchSerial] = useState("")

    useEffect(() => {
        if (popUpText !== null) {
            const timeout = setTimeout(() => {
                setPopUpText(null);
            }, 5000);
            return () => clearTimeout(timeout)
        }
    }, [popUpText])

    const popupHandler = (text) => {
        console.log(text)
        setPopUpText(text)
    }

    const getDisplayData = (serial) => {
        setSearchSerial(serial)
        if (serial !== "", serial.length > 8) {
            SlaService.getSlaData(serial).then((data) => {
                setSlaData(data);
            });
        } else if (serial === "") {
            setSlaData({})
        }
    };

    const getAddress = (street, city) => {
        if (street == null && city == null) {
            return "";
        }

        if (street == null) {
            return city;
        }

        if (city == null) {
            return street;
        }

        return `${street}, ${city}`;
    };

    return (
        <div className={styles.page}>
            <MainMenu />
            <div className={styles.submenu}>
                <SlaSubMenu firmwareLink={slaData.link} searchOnClick={getDisplayData} />
            </div>
            <div className={styles.banner}>
                <TitleBanner color="White" title="SLA Checker" subtitle="Find customer, display, and subscription data per display." imageContainerClassName={styles.backgroundImage} />
            </div>
            <div className={styles.content}>
                <div className={styles.inputGroup}>
                    <ItemTitle title="Display" />
                    <div className={styles.inputContainer}>
                        <InputField type="Text" backgroundType="Grey" title="Registered" withTitle={true} placeholder={""} inputValue={slaData.registered} />
                        <InputField type="Text" backgroundType="Grey" title="Model" withTitle={true} placeholder={""} inputValue={slaData.model} />
                        <div className={styles.havePopup} onMouseEnter={() => { popupHandler(slaData.initfirm) }}>
                            {popUpText !== null && popUpText !== undefined && popUpText !== "" && popUpText === slaData.initfirm && <Popup className={styles.popup} text={slaData.initfirm} popupDirection={PopUpDirection.bottomLeft} />}
                            <InputField type="Text" backgroundType="Grey" title="Initial Firmware" withTitle={true} placeholder={""} inputValue={slaData.initfirm} />
                        </div>
                        <InputField type="Text" backgroundType="Grey" title="Panel ID" withTitle={true} placeholder={""} inputValue={slaData.panel_id} />
                        <InputField type="Text" backgroundType="Grey" title="Type" withTitle={true} placeholder={""} inputValue={slaData.type} />
                        <div className={styles.havePopup} onMouseEnter={() => { popupHandler(slaData.latest_firm) }}>
                            {popUpText !== null && popUpText !== undefined && popUpText !== "" && popUpText === slaData.latest_firm && <Popup className={styles.popup} text={slaData.latest_firm} popupDirection={PopUpDirection.bottomLeft} />}
                            <InputField type="Text" backgroundType="Grey" title="Latest Firmware" withTitle={true} placeholder={""} inputValue={slaData.latest_firm} />
                        </div>
                        <InputField type="Text" backgroundType="Grey" title="Mac" withTitle={true} placeholder={""} inputValue={slaData.mac} />
                        <InputField type="Text" backgroundType="Grey" title="OPS" withTitle={true} placeholder={""} inputValue={slaData.serial_module} />
                        <div className={styles.havePopup} onMouseEnter={() => { popupHandler(slaData.heartbeat) }}>
                            {popUpText !== null && popUpText !== undefined && popUpText !== "" && popUpText === slaData.heartbeat && <Popup className={styles.popup} text={slaData.heartbeat} popupDirection={PopUpDirection.bottomLeft} />}
                            <InputField type="Text" backgroundType="Grey" title="Heartbeat" withTitle={true} placeholder={""} inputValue={slaData.heartbeat} />
                        </div>
                        <InputField type="Text" backgroundType="Grey" title="License Type" withTitle={true} placeholder={""} inputValue={slaData.type} />
                        <InputField type="Text" backgroundType="Grey" title="Serial Number" withTitle={true} placeholder={""} inputValue={slaData.serial ? slaData.serial : searchSerial} />
                        <InputField type="Text" backgroundType="Grey" title="Brix Serial Number" withTitle={true} placeholder={""} inputValue={slaData.keycode} />
                    </div>
                </div>
                {/* <div className={styles.inputGroup}>
                    <ItemTitle title="Subscription" />
                    <div className={styles.inputContainer}>
                        
                    </div>
                </div> */}
                <div className={styles.inputGroup}>
                    <ItemTitle title="Customer" />
                    <div className={styles.inputContainer}>
                        <div className={styles.havePopup} onMouseEnter={() => { popupHandler(slaData.company) }}>
                            {popUpText !== null && popUpText !== undefined && popUpText !== "" && popUpText === slaData.company && <Popup className={styles.popup} text={slaData.company} popupDirection={PopUpDirection.bottomLeft} />}
                            <InputField type="Text" backgroundType="Grey" title="Company" withTitle={true} placeholder={""} inputValue={slaData.company} />
                        </div>
                        <InputField type="Text" backgroundType="Grey" title="Branche" withTitle={true} placeholder={""} inputValue={slaData.branch} />

                        <InputField type="Text" backgroundType="Grey" title="Postal Code" withTitle={true} placeholder={""} inpvutValue={slaData.zip} />
                        <InputField type="Text" backgroundType="Grey" title="Marketing" withTitle={true} placeholder={""} inputValue={slaData.marketing} />
                        <div className={styles.havePopup} onMouseEnter={() => { popupHandler(slaData.email) }}>
                            {popUpText !== null && popUpText !== undefined && popUpText !== "" && popUpText === slaData.email && <Popup className={styles.popup} text={slaData.email} popupDirection={PopUpDirection.bottomLeft} />}
                            <InputField type="Text" backgroundType="Grey" title="Email" withTitle={true} placeholder={""} inputValue={slaData.email} />
                        </div>
                        <InputField type="Text" backgroundType="Grey" title="Address" withTitle={true} placeholder={""} inputValue={getAddress(slaData.street, slaData.city)} />
                        <InputField type="Text" backgroundType="Grey" title="Country" withTitle={true} placeholder={""} inputValue={slaData.country} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SlaChecker;