import styles from "./LandingPage.module.css"
import { Button } from "@ctouch-europe-b-v/myctouch-component-library"
import { useAuth0 } from "@auth0/auth0-react";

const LandingPage = () => {
    const { loginWithRedirect } = useAuth0();
    return (
        <div className={styles.container}>
            <img src="./registration_background.jpg" alt="background" className={styles.background} />
            <div className={styles.overlay}>
                <div className={styles.content}>
                    <img src="./ctouch_logo.png" alt="background" className={styles.logo} />
                    <div className={styles.title}>MyCTOUCH</div>
                    <div className={styles.body}>
                        <div className={styles.bodyTitle}>Expand and Personalize</div>
                        Get the most our of your CTOUCH display. Setup and explore our latest software products and manage your users and organization, all with the security you are used to from CTOUCH.
                    </div>
                    <Button type={"Secondary Outline Light"} text={"Log In/Sign Up"} onClick={() => loginWithRedirect()} />
                </div>
            </div>

        </div>
    );
}

export default LandingPage