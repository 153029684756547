import { useAuth0 } from "@auth0/auth0-react"
import { ModalContainer, ModalTitle, ModalParagraph, ModalButtons } from "@ctouch-europe-b-v/myctouch-component-library"

const AdminNotifiedModal = () => {
    const { logout } = useAuth0()

    return (
        <ModalContainer >
            <ModalTitle title={"Admin notified"} />
            <ModalParagraph version={"Secondary"} text="We have notified your admin to setup an account. You can continue to setup your account after the organization is created." />
            <ModalButtons primaryButton primaryButtonText="Close" onPrimaryButtonClick={() => { logout({ logoutParams: { returnTo: window.location.origin } }) }} />
        </ModalContainer>
    )
}

export default AdminNotifiedModal;