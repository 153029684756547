import {MenuContainer, MenuItem,} from '@ctouch-europe-b-v/myctouch-component-library'
import styles from "./OriginSubMenu.module.css"
import {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {OriginSessionService} from "../../../../services/OriginSessionService";

const OriginSubMenu = () => {
    const user = useSelector((state) => state.user.value);
    const selectedUser = useSelector((state) => state.selectedUser.value);
    const selectedCompany = useSelector((state) => state.selectedCompany.value);
    const [originSession, setOriginSession] = useState(null);
    const [displaySessionDate, setDisplaySessionDate] = useState(null);
    const defaultSession = {
        "Id": "No sessions yet",
        "Display_id": "No sessions yet",
        "Email": "No sessions yet",
        "Start_Time": "No sessions yet",
        "End_Time": "No sessions yet"
    }
    const fetchLastSession = useCallback(async () => {
                let session;
                if (selectedUser === null) {
                    try {
                        if (selectedCompany) {
                            setOriginSession(defaultSession)
                            setDisplaySessionDate(defaultSession.End_Time)
                        } else {
                            session = await OriginSessionService.getOriginSession(user.Email)
                            if (!session.End_Date) {
                                setDisplaySessionDate(session.Start_Time)
                            }
                            setOriginSession(session)
                        }
                    } catch (error) {
                        console.error(error);
                        setOriginSession(defaultSession)
                    }
                } else {
                    if (selectedCompany) {

                        console.log("hier")
                    } else {
                        try {
                            session = await OriginSessionService.getOriginSession(selectedUser.Email)
                            if (!session.End_Date) {
                                setDisplaySessionDate(session.Start_Time)
                            }
                            setOriginSession(session)
                        } catch (error) {
                            console.error(error);
                            setOriginSession(defaultSession)
                        }
                    }
                }
            }, [selectedUser, user.Email]
        )
    ;

    useEffect(() => {
        fetchLastSession();
    }, [selectedUser]);


    const calculateElapsedTimeInMinutes = (startTime, endTime) => {

        if (startTime === null || selectedCompany) {
            return "No sessions yet"
        } else if (endTime === null) {
            const startTimestamp = new Date(startTime).getTime();
            const endTimestamp = new Date();
            const difference = endTimestamp - startTimestamp;
            return Math.floor(difference / (1000 * 60));
        } else {
            const startTimestamp = new Date(startTime).getTime();
            const endTimestamp = new Date(endTime).getTime();
            const difference = endTimestamp - startTimestamp;
            return Math.floor(difference / (1000 * 60)) + " minutes";
        }
    };

    return (

        <div className={styles.container}>
            <MenuContainer className={styles.menuContainer} title={"User"}>
                <MenuItem type={"Title"} title={"Easy access"}></MenuItem>
                <MenuItem type={"Paragraph"}
                          text={"By entering the display’s session ID, you can safely access the files from your connected cloud accounts on-screen. No password required. "}></MenuItem>
                <MenuItem type={"Paragraph"} text={"Simply disconnect to revoke on-screen access."}/>
                <MenuItem type={"Divider"}></MenuItem>
                <MenuItem type={"Title"} title={"Last session"}></MenuItem>
                <MenuItem type={"Item"} text={originSession?.Display_id} iconName="display"></MenuItem>
                {/*TODO: Change after merge to display name.*/}
                <MenuItem type={"Item"} text={displaySessionDate} iconName="calendar"></MenuItem>
                <MenuItem type={"Item"}
                          text={calculateElapsedTimeInMinutes(originSession?.Start_Time, originSession?.End_Time)}
                          iconName="clock"></MenuItem>
            </MenuContainer>
        </div>)
}


export default OriginSubMenu;