import { useEffect, useState } from "react";
import VerifyMailModal from "../../ui/organisms/verifyMailModal/VerifyMailModal";
import styles from "./Registration.module.css";
import FirstModal from "../../ui/organisms/firstModal/FirstModal";
import SetupAdminModal from "../../ui/organisms/setupAdminModal/SetupAdminModal";
import ChooseCompanyModal from "../../ui/organisms/chooseCompanyModal/ChooseCompanyModal";
import WaitingForAdminModal from "../../ui/organisms/waitingForAdminModal/WaitingForAdminModal";
import NotifyAdminModal from "../../ui/organisms/notifyAdminModal/NotifyAdminModal";
import AdminNotifiedModal from "../../ui/organisms/adminNotifiedModal/AdminNotifiedModal";
import IllegalMailModal from "../../ui/organisms/illegalMailModal/IllegalMailModal";
import ThanksModal from './../../ui/organisms/thanksModal/ThanksModal';
import { SphereCompanyService } from "../../../services/SphereCompanyService";
import { SphereUserService } from "../../../services/SphereUserService";
import { useAuth0 } from "@auth0/auth0-react";
import { UserService } from "../../../services/UserService";
import { CompanyService } from "../../../services/CompanyService";
import DeniedUserModal from "../../ui/organisms/deniedUserModal/DeniedUserModal";

const Registration = () => {
    const { user } = useAuth0()
    const [pageIndex, setPageIndex] = useState(0);
    const [companyId, setCompanyId] = useState(null);
    const [company, setCompany] = useState(null);
    const [admin, setAdmin] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [userStatus, setUserStatus] = useState(null);
    const illegalDomains = ["gmail", "outlook", "hotmail", "protonmail", "icloud", "yahoo", "me"];
    const [userCreated, setUserCreated] = useState(false)

    const handlePageIndexChange = (index) => {
        setPageIndex(index)
    }

    const setCompanyAndAdmin = (company = null) => {
        setIsLoading(true);
        setCompany(company);
        SphereCompanyService.checkIfCompanyExists(user.email).then((response) => {
            setAdmin(response.data.filter((data) => data.id === companyId)[0]?.admin)
            setIsLoading(false);
        });
    }

    const updateUser = () => {
        SphereUserService.update({ email: user.email, company_id: companyId })
    }

    const checkUserStatus = async () => {
        return new Promise((resolve, reject) => {
            UserService.getUser(user.email).then(async (response) => {
                if (response.CompanyId === null) {
                    resolve(["User Created"])
                }
                await CompanyService.getCompany(response.CompanyId).then(companyResponse => {
                    setCompany(companyResponse)

                    if (response.UserStatus === "Denied") {
                        setCompanyId(response.CompanyId);
                        resolve(["User Denied", companyResponse])
                    } else if (response.UserStatus === "Invited") {
                        setCompanyId(response.CompanyId);
                        resolve(["User Invited", companyResponse])
                    } else if (response.UserStatus === "Pending") {
                        setCompanyId(response.CompanyId);
                        resolve(["User Pending", companyResponse])
                    }
                })
            }).catch(e => {
                if (e.response.data[0] === "The user is not known in our data set" && !userCreated) {
                    SphereUserService.create({ email: user.email, company_id: null, verified_by_admin: 0, is_admin: 0, user_status: "Pending", sphere_access: 0 }).then((response) => {
                        setUserCreated(true)
                        resolve(["User Created"])
                    }).catch(e => console.log("something went wrong"))
                }
            })
        })
    }

    useEffect(() => {
        //TO DO: First check if user already exists and what user_status user has.
        // - If no user exists, create user with status pending
        // - If user exists with invited status, update it to pending status
        // - If user has denied status, show denied modal
        //TO DO: Create also have User_status be Pending
        setIsLoading(true)
        checkUserStatus().then((response) => {
            setUserStatus(response[0].split(" ")[1])
            if (response[0] !== "User Denied") {
                SphereCompanyService.checkIfCompanyExists(user.email).then((response) => {
                    if (!response.data[0]?.id) {
                        setCompanyId(null);
                        setIsLoading(false);
                    } else {
                        setCompanyId(response.data[0].id);
                        setIsLoading(false);
                    }
                })
            } else {
                setUserStatus(response[0].split(" ")[1])
                setCompanyAndAdmin(response[1])
            }
            setIsLoading(false)
        })
    }, [])

    const companyExistsArray = [<ChooseCompanyModal email={user.email} setCompany={() => { setCompanyAndAdmin(); updateUser() }} createNew={() => setCompanyId(null)} setPage={handlePageIndexChange} />, <WaitingForAdminModal name={company?.name ? company?.name : company?.Name} email={admin} setPage={handlePageIndexChange} />]
    const companyNotExistArray = [<FirstModal setPage={handlePageIndexChange} />, <SetupAdminModal setCompany={setCompany} user={user} setPage={handlePageIndexChange} />, <NotifyAdminModal user={user} setPage={handlePageIndexChange} />, <AdminNotifiedModal />, <ThanksModal name={company?.name ? company.name : company?.companyName} />]
    const verifiedFlow = companyId ? companyExistsArray[pageIndex] : companyNotExistArray[pageIndex]
    const userStatusFlow = userStatus === "Invited" || userStatus === "Pending" || userStatus === "Created" ? verifiedFlow : userStatus === "Denied" ? <DeniedUserModal name={company?.Name} admin={admin} /> : <WaitingForAdminModal name={company?.name} email={admin} mailAlreadySent />
    const isVerified = user.email_verified ? userStatusFlow : <VerifyMailModal />
    const showPopUp = illegalDomains.includes(user.email.split("@")[1].split(".")[0]) ? <IllegalMailModal /> : isVerified
    return (
        <div className={styles.container}>
            <img src="./registration_background.jpg" alt="background" className={styles.background} />
            <div className={styles.overlay} >
                <img src={"./ctouch_logo.png"} alt={"Logo"} className={styles.logo} />
            </div>
            {!isLoading && showPopUp}
        </div>
    );
}

export default Registration;