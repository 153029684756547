import { AxiosService } from './AxiosService';

const baseUrl = "company";
const axiosService = AxiosService(process.env.REACT_APP_API_URL)

export const CompanyService = {
  async getCompany(companyId) {
    return await axiosService.getRequest(`${baseUrl}/${companyId}`);
  },
  async getResellerCompany(resellerCode) {
    return await axiosService.getRequest(`${baseUrl}/reseller/${resellerCode}`)
  },
  async getCompaniesWithResellerCode(resellerCode) {
    return await axiosService.getRequest(`${baseUrl}/companyListByResellerCode/${resellerCode}`);
  },
  async getCompaniesWithCtouchSupport() {
    return await axiosService.getRequest(`${baseUrl}/supportedCompanyList`);
  },
  async updateCompanyCTOUCHSupport(companyId, ctouchSupport) {
    return await axiosService.putRequest(`${baseUrl}/ctouchSupport/${companyId}/${ctouchSupport}`)
  },
  async updateCompanyResellerCode(companyId, resellerCode) {
    return await axiosService.putRequest(`${baseUrl}/resellerCode/${companyId}/${resellerCode}`)
  }
};