import { Switch, InputField, InputLabel, ImageList } from "@ctouch-europe-b-v/myctouch-component-library"
import styles from "./EchoSettingGroup.module.css"
import ConfirmableTextInput from './../confirmableTextInput/ConfirmableTextInput';
import ColorPicker from "../colorPicker/ColorPicker";
import { useEffect, useState } from "react";
import TitleSubTitle from "../../atoms/titleSubtitle/TitleSubtitle";
const EchoSettingGroup = ({ areTitlesLeft, title, subtitle, settings, onSettingChanged, profile }) => {
    const [groupSettings, setGroupSettings] = useState(settings);

    useEffect(() => {
        setGroupSettings(settings)
    }, [settings])

    const isSettingDisabled = (setting) => {
        if (profile.ProfileName === "Default") return true
        const timeAndDateSetting = groupSettings.find(s => s.Setting.LauncherSettingName === "timeAndDate");
        const logoSetting = groupSettings.find(s => s.Setting.LauncherSettingName === "logo");
        const sourceSetting = groupSettings.find(s => s.Setting.LauncherSettingName === "source");

        if (setting.Setting.LauncherSettingName === "timeAndDateColor" || setting.Setting.LauncherSettingName === "timeAndDateFormat") {
            if (timeAndDateSetting?.CurrentValue === "Disabled") {
                return true;
            }
        }

        if (setting.Setting.LauncherSettingName === "logoSource" || setting.Setting.LauncherSettingName === "logoUrl") {
            if (logoSetting?.CurrentValue === "Disabled") {
                return true;
            }
        }

        if (setting.Setting.LauncherSettingName === "backgroundImageUrl" && sourceSetting?.CurrentValue !== "Included") {
            return true;
        }

        return false;
    }

    const handleInputChange = (id, value, property, settingId) => {
        const parsedValue = (typeof (value) === "boolean") ? (value) ? 1 : 0 : value
        if (property === "source") {
            var backgroundSetting;
            if (value === "Included") {
                const setting = groupSettings.filter((groupSetting) => groupSetting?.Setting?.LauncherSettingName === "backgroundImageUrl")[0]
                backgroundSetting = { "property": setting.Setting.LauncherSettingName, "value": setting.CurrentValue, "id": setting.Id, "settingId": setting.Setting.Id }
            } else if (value === "Image URL") {
                const setting = groupSettings.filter((groupSetting) => groupSetting.Setting.LauncherSettingName === "imageUrl")[0]
                backgroundSetting = { "property": setting.Setting.LauncherSettingName, "value": setting.CurrentValue, "id": setting.Id, "settingId": setting.Setting.Id }
            } else {
                const setting = groupSettings.filter((groupSetting) => groupSetting.Setting.LauncherSettingName === "pageUrl")[0]
                backgroundSetting = { "property": setting.Setting.LauncherSettingName, "value": setting.CurrentValue, "id": setting.Id, "settingId": setting.Setting.Id }
            }
            onSettingChanged(backgroundSetting)
        }
        const changedSetting = { "property": property, "value": parsedValue.toString(), "id": id, "settingId": settingId }
        onSettingChanged(changedSetting)
        const updatedList = groupSettings.map(setting => {
            if (setting.Id === id) {
                if (setting.Setting.SettingType === "switch") {
                    value = value ? "1" : "0";
                }
                setting.CurrentValue = value;
            }
            return setting;
        });
        setGroupSettings(updatedList);
    };

    return (
        <div className={styles.container}>
            <TitleSubTitle title={title} subtitle={subtitle} />
            <div className={styles.settingContainer}>
                {
                    groupSettings && groupSettings.map((setting) => {
                        var displayedSetting;
                        switch (setting.Setting.SettingType) {
                            case "switch":
                                displayedSetting = <Switch isDisabled={isSettingDisabled(setting)} titleLeft={areTitlesLeft} withTitle title={setting.Setting.PresentationName} value={setting.CurrentValue === "1"} onChange={(e) => { handleInputChange(setting.Id, e.target.checked, setting.Setting.LauncherSettingName, setting.Setting.Id) }} />
                                break;
                            case "dropdown":
                                displayedSetting = <InputField onChange={(e) => { handleInputChange(setting.Id, e.target.value, setting.Setting.LauncherSettingName, setting.Setting.Id) }} withTitle type="DropDown" state={isSettingDisabled(setting) ? "Disabled" : "Active"} title={setting.Setting.PresentationName} inputValue={setting.CurrentValue} options={setting.Setting.LauncherSettingOptions.split(",").map((option) => { return { "text": option } })} className={styles.input} />
                                break;
                            case "imagepicker":
                                displayedSetting = <div className={styles.imageListContainer}>
                                    <InputLabel title={setting.Setting.PresentationName} />
                                    <ImageList currentImg={setting.CurrentValue} onClick={(value) => handleInputChange(setting.Id, value, setting.Setting.LauncherSettingName, setting.Setting.Id)} isDisabled={isSettingDisabled(setting)} images={[{ "url": "https://ctouchwebtools.blob.core.windows.net/echo/echobg1.jpg", "alt": "echobg1" }, { "url": "https://ctouchwebtools.blob.core.windows.net/echo/echobg2.jpg", "alt": "echobg2" }, { "url": "https://ctouchwebtools.blob.core.windows.net/echo/echobg3.jpg", "alt": "echobg3" }, { "url": "https://ctouchwebtools.blob.core.windows.net/echo/echobg4.jpg", "alt": "echobg4" },]} />
                                </div>
                                break;
                            case "url":
                                if ((setting.Setting.LauncherSettingName === "imageUrl" && groupSettings.filter((setting) => setting.Setting.LauncherSettingName === "source")[0]?.CurrentValue !== "Image URL") || (setting.Setting.LauncherSettingName === "pageUrl" && groupSettings.filter((setting) => setting.Setting.LauncherSettingName === "source")[0]?.CurrentValue !== "Page URL")) {
                                    displayedSetting = null
                                } else {
                                    displayedSetting = <ConfirmableTextInput onConfirm={(value) => { handleInputChange(setting.Id, value, setting.Setting.LauncherSettingName, setting.Setting.Id) }} isDisabled={isSettingDisabled(setting)} placeholder={setting.placeholder} title={setting.Setting.PresentationName} buttonText={"Save"} value={setting.CurrentValue} />
                                }
                                break;
                            case "colorpicker":
                                displayedSetting = <ColorPicker isDisabled={isSettingDisabled(setting)} title={setting.Setting.PresentationName} value={setting.CurrentValue} setValue={(value) => { handleInputChange(setting.Id, value, setting.Setting.LauncherSettingName, setting.Setting.Id); }} />
                                break;
                            default:
                                displayedSetting = <InputField isDisabled={isSettingDisabled(setting)} withTitle type="DropDown" title={setting.Setting.PresentationName} className={styles.input} />
                                break;
                        }
                        return displayedSetting
                    })
                }
            </div>
        </div>
    );
}

export default EchoSettingGroup;