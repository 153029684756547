import { ModalContainer, ModalTitle, ModalParagraph, ModalButtons, ModalInputField } from "@ctouch-europe-b-v/myctouch-component-library"
import { useEffect, useState } from "react";
import { SphereCompanyService } from "../../../../services/SphereCompanyService";

const ChooseCompanyModal = ({ email, setCompany, setPage, createNew }) => {
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);

    useEffect(() => {
        SphereCompanyService.getCompaniesByEmail(email?.substring(email?.indexOf("@") + 1)).then((response) => {
            setCompanies(response.data);
        })
    }, [])

    useEffect(() => {
        console.log(selectedCompany);
    }, [selectedCompany])

    return (
        <ModalContainer >
            <ModalTitle title={"Select Organization"} />

            <ModalParagraph version={"Secondary"} text="It looks like there are existing organizations within your domain. Please select your organization or create a new one if desired." />
            <ModalInputField type="Dropdown" value={selectedCompany !== null ? selectedCompany.name : undefined} placeholder={"Select"} description={"Organization"} options={companies} dropdownProp={["name"]} onChange={(e) => { setSelectedCompany(e.target.value) }} />
            <ModalButtons primaryButton cancelButton cancelButtonText="Create New" onCancelButtonClick={() => { createNew() }} secondaryButtonText="Create New" primaryButtonText="Join" onPrimaryButtonClick={() => { setCompany(companies.filter((company) => company.name === selectedCompany)[0]); setPage(1) }} />

        </ModalContainer>
    )
}

export default ChooseCompanyModal;